import angular from "angular";
import { complianceDashboardComponent } from "./components/compliance-dashboard/compliance-dashboard.component";
import { complianceItemRecords } from "./components/compliance-item-records/compliance-item-records.component";
import { newComplianceItemFields } from "./components/new-compliance-item-fields/new-compliance-item-fields.component";
import { complianceConsts } from "./compliance.consts";
import { caregiverCompliance } from "./components/caregiver-compliance/caregiver-compliance.component";
import { complianceService } from "./compliance.service";
import { CompService } from "./comp.service";
import { caregiverComplianceGroupModalCtrl } from "./controllers/caregiverComplianceGroupModalCtrl";
import { caregiverComplianceInstanceModalCtrl } from "./controllers/caregiverComplianceInstanceModalCtrl";
import { caregiversComplianceCtrl } from "./controllers/caregiversComplianceCtrl";
import { complianceItemEditModalCtrl } from "./controllers/complianceItemEditModalCtrl";
import { newComplianceItemModalCtrl } from "./controllers/newComplianceItemModalCtrl";
import { rejectComplianceItemPendingUploadModalCtrl } from "./controllers/rejectComplianceItemPendingUploadModalCtrl";
import { uploadComplianceInstanceMultipleDocModalCtrl } from "./controllers/uploadComplianceInstanceMultipleDocModalCtrl";

export function registerComplianceModule() {
  return (
    angular
      .module("dashboard")
      // Constants
      .constant("complianceConsts", complianceConsts)

      // Factories
      .factory("complianceService", complianceService)

      //  Services
      .service("compService", CompService)

      // Components
      .component("complianceItemRecords", complianceItemRecords)
      .component("caregiverCompliance", caregiverCompliance)
      .component("newComplianceItemFields", newComplianceItemFields)
      .component(complianceDashboardComponent.$name, complianceDashboardComponent)

      // Controllers
      .controller("caregiverComplianceGroupModalCtrl", caregiverComplianceGroupModalCtrl)
      .controller("caregiverComplianceInstanceModalCtrl", caregiverComplianceInstanceModalCtrl)
      .controller("caregiversComplianceCtrl", caregiversComplianceCtrl)
      .controller("complianceItemEditModalCtrl", complianceItemEditModalCtrl)
      .controller("newComplianceItemModalCtrl", newComplianceItemModalCtrl)
      .controller("rejectComplianceItemPendingUploadModalCtrl", rejectComplianceItemPendingUploadModalCtrl)
      .controller("uploadComplianceInstanceMultipleDocModalCtrl", uploadComplianceInstanceMultipleDocModalCtrl)
  );
}
