import { PatientId } from "../messages/ids";
import { PatientExtendedData } from "../messages/patient";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

//! @ngInject
export class PatientService {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private endpoint: Endpoint,
    private api: Api
  ) {}

  /**
   * Get patient by id
   */
  async getById(patientId: PatientId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/patients/:patientId",
      params: {
        agencyId: this.$rootScope.agencyId,
        patientId: patientId,
      },
    });

    return this.api.get<PatientExtendedData>(url).then(({ data }) => data);
  }
}

