import { assertNever } from "../consts/assertNever.const";
import { CaregiverStatus } from "../messages/caregiver";

export type LabelColor = "red" | "green" | "orange" | "gray" | "lightblue";

export function getCaregiverStatusColor(status: CaregiverStatus): LabelColor {
  switch (status) {
    case "PENDING":
      return "lightblue";

    case "ACTIVE":
      return "green";

    case "ON_HOLD":
    case "ON_LEAVE":
      return "orange";

    case "TERMINATED":
      return "red";

    case "JOIN_REQUEST":
    case "SUSPENDED":
    case "QUIT":
      return "gray";

    default:
      return assertNever(status);
  }
}
