import { ChronoUnit, convert, LocalDate } from "js-joda";
import { ApiErrors } from "../consts/apiErrors.const";
import { DateUtils } from "../consts/dateUtils";
import { PatientId, PlanOfCareId } from "../messages/ids";
import { GeneratePatientDutySheetRow, PlanOfCareService } from "../services/planOfCareService";

interface DutySheetCsvRowPayload {
  label: string;
  code: string;
  days: {
    [day: string]: {
      caregiverName: string;
      isCompleted: boolean;
    }[];
  };
}

//! @ngInject
export class ExportDutySheetDataModalCtrl {
  constructor(
    private $timeout: ng.ITimeoutService,
    private toaster: toaster.IToasterService,
    private apiErrors: ApiErrors,
    private planOfCareService: PlanOfCareService,
    private dateUtils: DateUtils,
    private patientId: PatientId,
    private planOfCareId: PlanOfCareId,
    private fromDate: LocalDate
  ) {
    this.form = {
      from: convert(this.fromDate).toDate(),
      to: convert(this.fromDate.plusDays(6)).toDate(),
    };
  }

  form: {
    from: Date;
    to: Date;
  };

  isExporting = false;

  getTooltip() {
    const { from, to } = this.parseForm(this.form);

    if (from.until(to, ChronoUnit.DAYS) > 7) {
      return "Duty sheet can be generated for a maximum of 7 days";
    }

    return null;
  }

  handleClickExportAsCsv() {
    const { from, to } = this.parseForm(this.form);

    if (from.isAfter(to)) {
      this.toaster.error("From date must be before to date");
      return;
    }

    this.isExporting = true;
    this.planOfCareService
      .generateDutySheetsCsv({
        patientId: this.patientId,
        from: from,
        to: to,
        planOfCareId: this.planOfCareId,
      })
      .then(this.generateCsvFromDuties.bind(this))
      .catch((error) => this.toaster.error(this.apiErrors.format(error, "Failed to generate CSV")))
      .finally(() => this.$timeout(() => (this.isExporting = false), 0));
  }

  handleClickExportAsPdf() {
    const { from, to } = this.parseForm(this.form);

    if (from.isAfter(to)) {
      this.toaster.error("From date must be before to date");
      return;
    }

    this.isExporting = true;
    this.planOfCareService
      .generateDutySheetPdf({
        patientId: this.patientId,
        planOfCareId: this.planOfCareId,
        from: from,
        to: to,
      })
      .then((fileUrl) => window.open(fileUrl, "_blank"))
      .catch((error) => {
        console.error(error);
        this.toaster.error(this.apiErrors.format(error, "Failed to generate PDF"));
      })
      .finally(() => (this.isExporting = false));
  }

  private generateCsvFromDuties(duties: GeneratePatientDutySheetRow[]) {
    const { from, to } = this.parseForm(this.form);

    const columns = [
      "Task name",
      "Task code",
      ...this.getDatesRangeArray({ from: from, to: to }).map((x) => x.toString()),
    ].join(",");

    const rows = this.mapToCsvRowPayload(duties).map(this.mapCsvRowPayloadToRow).join("\r\n");

    this.downloadCsv({
      filename: `${from}-${to}.csv`,
      content: `data:text/csv;charset=utf-8,${encodeURIComponent(`"\uFEFF"${columns}\r\n${rows}`)}`,
    });

    return;
  }

  private downloadCsv(params: { filename: string; content: string }) {
    const link = document.createElement("a");
    link.setAttribute("href", params.content);
    link.setAttribute("download", params.filename);
    document.body.appendChild(link);
    link.click();
  }

  private mapCsvRowPayloadToRow(payload: DutySheetCsvRowPayload) {
    return [
      payload.label,
      payload.code,
      ...Object.entries(payload.days).map(([, tasks]) => {
        const cell = tasks
          .map(
            ({ caregiverName, isCompleted }) =>
              `${caregiverName} (${isCompleted ? "Fulfilled" : "Not fulfilled"})`
          )
          .join(", ");

        return `"${cell}"`;
      }),
    ];
  }

  private mapToCsvRowPayload(duties: GeneratePatientDutySheetRow[]): DutySheetCsvRowPayload[] {
    const { from, to } = this.parseForm(this.form);
    const codes = [...new Set([...duties.map((x) => x.item.code)])];

    return codes.map((code) => {
      const item = duties.find((x) => x.item.code === code)!.item;

      return {
        label: item.label,
        code: code,
        days: this.getDatesRangeArray({ from, to }).reduce((acc, day) => {
          acc[day.toString()] = duties
            .filter((duty) => duty.startDate.equals(day) && duty.item.code === code)
            .map((duty) => ({
              caregiverName: duty.caregiverName,
              isCompleted: duty.isCompleted,
            }));

          return acc;
        }, {} as DutySheetCsvRowPayload["days"]),
      };
    });
  }

  private getDatesRangeArray(params: { from: LocalDate; to: LocalDate }) {
    if (params.from.isAfter(params.to)) {
      throw new Error("From date must be before to date");
    }

    const days: LocalDate[] = [];

    for (let i = 0; i < params.from.until(params.to, ChronoUnit.DAYS); i++) {
      days.push(params.from.plusDays(i));
    }

    return days;
  }

  private parseForm(form: typeof this.form) {
    return {
      from: this.dateUtils.dateToLocalDate(form.from),
      to: this.dateUtils.dateToLocalDate(form.to),
    };
  }
}
