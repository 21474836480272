import { LocalDate } from "js-joda";
import {
  ComplianceCaregiverRecord,
  ComplianceDocument,
  DashboardComplianceItem,
} from "../models/compliance";
import { CaregiverDict } from "../scripts/messages/caregiver";
import {
  MessageComplianceCaregiverRecord,
  MessageComplianceDocument,
  MessageDashboardComplianceItem,
} from "../scripts/messages/compliance";
import { CaregiverDocumentTypeId } from "../scripts/messages/ids";
import { assertDefined } from "../scripts/utils/generalUtils";

export class ComplianceParser {
  public static parseComplianceDocumentMap(
    records: MessageComplianceDocument[],
    followupDocumentTypesMap: Map<CaregiverDocumentTypeId, CaregiverDocumentTypeId>
  ): Map<CaregiverDocumentTypeId, ComplianceDocument> {
    const map = new Map<CaregiverDocumentTypeId, ComplianceDocument>();

    for (const record of records) {
      map.set(record.id, this.parseComplianceDocument(record, followupDocumentTypesMap));
    }

    return map;
  }

  public static parseDashboardComplianceItem(
    record: MessageDashboardComplianceItem,
    documentsMap: Map<CaregiverDocumentTypeId, ComplianceDocument>
  ): DashboardComplianceItem {
    return {
      documentType: assertDefined(documentsMap.get(record.documentTypeId), "documentType"),
      expiryDate: record.expiryDate === null ? null : LocalDate.parse(record.expiryDate),
      dueDate: record.dueDate === null ? null : LocalDate.parse(record.dueDate),
      effectiveDate: record.effectiveDate === null ? null : LocalDate.parse(record.effectiveDate),
      status: record.status,
      latestInstanceId: record.latestInstanceId,
      uploadedAt: record.uploadedAt === null ? null : LocalDate.parse(record.uploadedAt),
      uploadedBy: record.uploadedBy,
      fields: record.fields,
    };
  }

  public static parseCaregiverComplianceItem(
    record: MessageComplianceCaregiverRecord,
    documentsMap: Map<CaregiverDocumentTypeId, ComplianceDocument>,
    caregiversMap: CaregiverDict
  ): ComplianceCaregiverRecord {
    return {
      caregiver: assertDefined(caregiversMap[record.caregiverId as unknown as number], "caregiver"),
      items: record.items.map((item) => this.parseDashboardComplianceItem(item, documentsMap)),
    };
  }

  public static parseComplianceDocument(
    record: MessageComplianceDocument,
    followupDocumentTypesMap: Map<CaregiverDocumentTypeId, CaregiverDocumentTypeId>
  ): ComplianceDocument {
    return {
      id: record.id,
      name: record.name,
      isRoot: record.isRoot,
      section: record.section,
      children: record.children,
      fields: record.fields,
      expires: record.expires,
      expiryAmount: record.expiryAmount,
      expiryUnit: record.expiryUnit,
      groupType: record.groupType,
      requireReVerification: record.requireReVerification,
      followupParentId: followupDocumentTypesMap.get(record.id) ?? null,
    };
  }

  public static getFollowupDocumentTypesMap = (types: MessageComplianceDocument[]) => {
    const followupDocumentTypesMap: Map<CaregiverDocumentTypeId, CaregiverDocumentTypeId> =
      new Map();

    for (const type of types) {
      for (const field of type.fields) {
        if (field.type === "Dropdown") {
          for (const option of field.possibleValues) {
            const followupId = option.followupDocumentRequireData?.followupDocumentTypeId;
            if (followupId !== undefined) {
              followupDocumentTypesMap.set(followupId, type.id);
            }
          }
        }
      }
    }

    return followupDocumentTypesMap;
  };
}
