import JSZip from "jszip";

//! @ngInject
export function billingInvoicesProcessor(
    $rootScope,
    DatabaseApi,
    $q,
    SweetAlert,
    $uibModal,
    $window,
    $http,
    wildcard,
    Consts,
    ProcessorModalProcessGenerator,
    toaster
) {

    //REMARK:  The following variables are global, since each download can be done one at a time
    let exportFlatData = { 
        files: null,
        errors: null
    };
    let zipFileUrl = null;

    function initData() {
        exportFlatData = { 
            files: null,
            errors: null
        };
        zipFileUrl = null;
    };

    const getExportingHistoryByVisitId = function (
        visitId,
        { agencyId, agencyMemberId }
    ) {
        return $http.get(
            wildcard(
                `${Consts.api}agencies/:agencyId/agency_members/:agencyMemberId/visits/:visitInstanceId/invoices`,
                agencyId,
                agencyMemberId,
                visitId
            )
        );
    };

    const generateInvoices = function (selectedItemIds) {
        return $q(function (resolve, reject) {
            const hasSelectedItemIds = selectedItemIds && selectedItemIds.length > 0;
            if (!hasSelectedItemIds) {
                toaster.pop("error", "No items were selected");
                reject();
            }

            const body = {
                ids: selectedItemIds
            };

            DatabaseApi.post("agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/create_visits_invoices", body).then(resolve, reject);
        });
    };

    const generateAndExportInvoices = (dataToExport, force) => {
        return $q((resolve, reject) => {
            initData();

            if (!dataToExport) {
                toaster.pop("error", "No data to export");
                reject();
            }

            if (force) {
                dataToExport.forceNew = true;
            }

            DatabaseApi.post(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/create_invoices_and_export`, dataToExport).then(function (res) {
                exportFlatData = {
                    files: res.data.results.map(result => result.files).flat(),
                    errors: res.data.results.map(result => result.errors).flat()
                };
                if (!exportFlatData.files.length) {
                    return reject(exportFlatData.errors);
                }
                resolve(exportFlatData);
            }, reject);
        });
    };

    const exportInvoices = (dataToExport, force) => {
        return $q((resolve, reject) => {
            initData();

            if (!dataToExport) {
                toaster.pop("error", "No data to export");
                reject();
            }

            if (force) {
                dataToExport.forceNew = true;
            }

            DatabaseApi.post("agencies/" + $rootScope.agencyId + "/export_invoices", dataToExport).then(function (res) {
                exportFlatData = {
                    files: res.data.results.map(result => result.files).flat(),
                    errors: res.data.results.map(result => result.errors).flat()
                };
                if (!exportFlatData.files.length) {
                    return reject(exportFlatData.errors);
                }
                resolve(exportFlatData);
            }, reject);
        });
    };

    const exportInvoiceAs = (dataToExport) => {
        return $q((resolve, reject) => {
            initData();

            DatabaseApi.post("agencies/" + $rootScope.agencyId + "/export_invoice_as", dataToExport).then(function (res) {
                exportFlatData = {
                    files: res.data.results.map(result => result.files).flat(),
                    errors: res.data.results.map(result => result.errors).flat()
                };
                if (!exportFlatData.files.length) {
                    return reject(exportFlatData.errors);
                }
                resolve(exportFlatData);
            }, reject);
        });
    };

    const recheckPendingInvoices = function (selectedItemIds) {
        return $q(function (resolve, reject) {
            const body = {
                invoiceIds: selectedItemIds
            };

            DatabaseApi.post("agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/recheck_pending_invoices", body).then(resolve, reject);
        });
    };

    const zipExportedFileUrls = function () {
        return $q(function (resolve, reject) {
            zipFileUrl = null;
            const filesData = [];
            const hasExportedFiles = exportFlatData.files && exportFlatData.files.length > 0;
            if (hasExportedFiles) {
                exportFlatData.files.forEach(function (fileUrl) {
                    if (fileUrl.url === "") {
                        filesData.push({ data: null });
                        completeZipIfFilesReady();
                    }
                    else {
                        fetch(fileUrl.url).then(function (response) {
                            if (response.status === 200 || response.status === 0) {
                                let fileName = fileUrl.name;
                                if (!fileName) {
                                    const split = fileUrl.url.split('/');
                                    fileName = split[split.length - 1].split('?')[0];
                                }
                                filesData.push({ data: response.blob(), name: fileName });
                                completeZipIfFilesReady();
                            }
                        }, function (error) {
                            reject();
                        });
                    }
                });
            }
            else {
                reject();
            }

            function completeZipIfFilesReady() {
                if (filesData.length === exportFlatData.files.length) {
                    zipFilesAndGetZipUrl().then(function (urlOfZipFile) {
                        zipFileUrl = urlOfZipFile;
                        resolve();
                    }, function (error) {
                        reject();
                    });
                }
            };

            function zipFilesAndGetZipUrl() {
                const zip = new JSZip();
                filesData.forEach(function (file) {
                    if (file.data) {
                        zip.file(file.name, file.data);
                    }
                });

                return zip.generateAsync({ type: "blob" }).then(function (content) {
                    const blob = new Blob([content], { type: 'application/zip' }),
                        url = $window.URL || $window.webkitURL;
                    return url.createObjectURL(blob);
                });
            };

        });
    };

    const downloadExportedZipFile = function () {
        const link = document.createElement("a");
        link.setAttribute("href", zipFileUrl);
        link.setAttribute("download", "medflyt-exported-visits.zip");
        document.body.appendChild(link);

        initData();

        link.click();
        link.remove();
    };

    const getProcessObjectToGenerate = function (selectedItemIds) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            return generateInvoices(selectedItemIds);
        }, "Generating in progress", 
           "Failed to generate", 
           "Generating completed", 
           "Generating partially completed", 
           `Generating is in progress. 
            Don’t try to invoice again; We will send you an email when we complete the process.`);
    };
    const getProcessObjectToExportByVisits = function (selectedVisitIds) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            const hasSelectedIds = selectedVisitIds && selectedVisitIds.length > 0;
            const dataToExport = hasSelectedIds ? { visitIds: selectedVisitIds } : null;
            return exportInvoices(dataToExport, false);
        }, "Exporting in progress", "Failed to export", "Exporting completed", "Exporting partially completed", "Failed to export");
    };
    const getProcessObjectToGenerateAndExportByVisits = function (selectedVisitIds) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            const hasSelectedIds = selectedVisitIds && selectedVisitIds.length > 0;
            const dataToExport = hasSelectedIds ? { ids: selectedVisitIds } : null;
            return generateAndExportInvoices(dataToExport, false);

        }, "Exporting in progress", 
           "Failed to export", 
           "Exporting completed", 
           "Exporting partially completed", 
           `Generating & Exporting is in progress. 
            Don’t try to invoice again; We will send you an email when we complete the process.`);
    };
    const getProcessObjectToForceExportByVisits = function (selectedVisitIds) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            const hasSelectedIds = selectedVisitIds && selectedVisitIds.length > 0;
            const dataToExport = hasSelectedIds ? { visitIds: selectedVisitIds } : null;
            return exportInvoices(dataToExport, true);
        }, "Exporting in progress", "Failed to export", "Exporting completed", "Exporting partially completed", "Failed to export");
    };
    const getProcessObjectToForceExportByInvoices = function (selectedInvoiceIds) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            const hasSelectedIds = selectedInvoiceIds && selectedInvoiceIds.length > 0;
            const dataToExport = hasSelectedIds ? { invoiceIds: selectedInvoiceIds } : null;
            return exportInvoices(dataToExport, true);
        }, "Exporting in progress", "Failed to export", "Exporting completed", "Exporting partially completed", "Failed to export");
    };
    const getProcessObjectToExportByInvoiceAs = function (selectedVisitIds, eBillingType) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            const hasSelectedIds = selectedVisitIds && selectedVisitIds.length > 0;
            const dataToExport = hasSelectedIds ? { visitIds: selectedVisitIds, eBillingType } : null;
            return exportInvoiceAs(dataToExport);
        }, "Exporting in progress", "Failed to export", "Exporting completed", "Exporting partially completed", "Failed to export");
    };
    const getProcessObjectToExportByInvoices = function (selectedInvoiceIds) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            const hasSelectedIds = selectedInvoiceIds && selectedInvoiceIds.length > 0;
            const dataToExport = hasSelectedIds ? { invoiceIds: selectedInvoiceIds } : null;
            return exportInvoices(dataToExport);
        }, "Exporting in progress", "Failed to export", "Exporting completed", "Exporting partially completed", "Failed to export");
    };
    const getProcessObjectToRecheckPending = function (selectedItemIds) {
        return ProcessorModalProcessGenerator.createNewProcess(function () {
            return recheckPendingInvoices(selectedItemIds);
        }, "Checking in progress", "Failed to check pending", "Recheck completed", "Recheck partially completed", "Failed to check pending");
    };
    const zippingProcess = ProcessorModalProcessGenerator.createNewProcess(zipExportedFileUrls,
        "Zipping files", "Failed to zip files", "Zipping files completed", "Zipping files partially completed", null);

    const addDataToExportToModalData = function (modalData) {
        modalData.onSuccessAction = downloadExportedZipFile;
        modalData.isSuccessActionAllowed = function () { return exportFlatData.files && zipFileUrl; };
        modalData.successActionText = "Download zip file";
        modalData.onCloseModal = function () { exportFlatData.files = null; };
    };
    const getModalDataToGenerate = function (selectedItemIds) {
        const modalData = {
            processes: [getProcessObjectToGenerate(selectedItemIds)],
            title: "Generate"
        };
        return modalData;
    };
    const getModalDataToGenerateAndExport = function (selectedItemIds) {
        const modalData = {
            processes: [
                getProcessObjectToGenerateAndExportByVisits(selectedItemIds),
                zippingProcess
            ],
            title: "Generate and export"
        };
        addDataToExportToModalData(modalData);
        return modalData;
    };
    const getModalDataToExportByVisits = function (selectedItemIds) {
        const modalData = {
            processes: [
                getProcessObjectToExportByVisits(selectedItemIds),
                zippingProcess
            ],
            title: "Export"
        };
        addDataToExportToModalData(modalData);
        return modalData;
    };
    const getModalDataToForceExportByVisits = function (selectedItemIds) {
        const modalData = {
            processes: [
                getProcessObjectToForceExportByVisits(selectedItemIds),
                zippingProcess
            ],
            title: "Export"
        };
        addDataToExportToModalData(modalData);
        return modalData;
    };
    const getModalDataToForceExportByInvoices = function (selectedItemIds) {
        const modalData = {
            processes: [
                getProcessObjectToForceExportByInvoices(selectedItemIds),
                zippingProcess
            ],
            title: "Export"
        };
        addDataToExportToModalData(modalData);
        return modalData;
    };
    const getModalDataToExportByInvoiceAs = function (selectedItemIds, extraParams) {
        const modalData = {
            processes: [
                getProcessObjectToExportByInvoiceAs(selectedItemIds, extraParams.eBillingType),
                zippingProcess
            ],
            title: "Export"
        };
        addDataToExportToModalData(modalData);
        return modalData;
    };
    const getModalDataToExportByInvoices = function (selectedItemIds) {
        const modalData = {
            processes: [
                getProcessObjectToExportByInvoices(selectedItemIds),
                zippingProcess
            ],
            title: "Export"
        };
        addDataToExportToModalData(modalData);
        return modalData;
    };
    const getModalDataToRecheckPendingByInvoices = function (selectedItemIds) {
        const modalData = {
            processes: [getProcessObjectToRecheckPending(selectedItemIds)],
            title: "Recheck"
        };
        return modalData;
    };

    const openInvoicesProcessorModal = function (getModalDataMethod, selectedItemIds, extraParams, successMessage, onAllProcessesCompletedAction) {
        const modalData = getModalDataMethod(selectedItemIds, extraParams);
        const countSelectedItemIds = selectedItemIds && selectedItemIds.length;

        SweetAlert.swal({
            title: modalData.title + " invoices",
            text: "Are you sure you want to " + modalData.title.toLowerCase() + " invoices for the " + countSelectedItemIds + " selected visits? ",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, " + modalData.title.toLowerCase(),
            closeOnConfirm: true,
            customClass: 'swal-generate-invoices-modal',
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                const modalInstance = $uibModal.open({
                    templateUrl: 'admin/views/processor-modal.html',
                    size: 'md',
                    controller: 'processorModalCtrl',
                    windowTopClass: "processor-modal",
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        modalData: function () { return modalData; }
                    }
                });
                modalInstance.result.then(function (response) {
                    if (response === "success") {
                        toaster.pop("success", successMessage);
                    }

                    if (onAllProcessesCompletedAction) {
                        onAllProcessesCompletedAction();
                    }
                }, function (error) {
                    toaster.pop("error", "Failed to send the selected invoices to the server", "Server error");
                });
            }
        });
    };

    const invoicesProcessor = {

        /*
        // example for 'onAllProcessesCompletedAction' method:
        onAllProcessesCompletedAction = function () {
            //DO SOMETHING
        };
        */

        getExportingHistoryByVisitId: getExportingHistoryByVisitId,

        openInvoicesGeneratorModalByVisits: function (visitIds, onAllProcessesCompletedAction) {
            const successMessage = "New invoices were generated successfully by the selected visits";
            openInvoicesProcessorModal(getModalDataToGenerate, visitIds, null, successMessage, onAllProcessesCompletedAction);
        },

        openInvoicesGeneratorAndExporterModalByVisits: function (visitIds, onAllProcessesCompletedAction) {
            const successMessage = "New invoices were generated and exported successfully by the selected visits";
            openInvoicesProcessorModal(getModalDataToGenerateAndExport, visitIds, null, successMessage, onAllProcessesCompletedAction);
        },

        openInvoicesExporterModalByVisits: function (visitIds, onAllProcessesCompletedAction) {
            const successMessage = "New invoices were exported successfully by the selected visits";
            openInvoicesProcessorModal(getModalDataToExportByVisits, visitIds, null, successMessage, onAllProcessesCompletedAction);
        },

        openInvoicesForceExporterModalByVisits: function (visitIds, onAllProcessesCompletedAction) {
            const successMessage = "Visits were successfully exported";
            openInvoicesProcessorModal(getModalDataToForceExportByVisits, visitIds, null, successMessage, onAllProcessesCompletedAction);
        },

        openInvoicesForceExporterModalByInvoices: function (invoiceIds, onAllProcessesCompletedAction) {
            const successMessage = "Visits were successfully exported";
            openInvoicesProcessorModal(getModalDataToForceExportByInvoices, invoiceIds, null, successMessage, onAllProcessesCompletedAction);
        },

        openInvoicesExporterModalByInvoiceAs: function (visitIds, onAllProcessesCompletedAction, extraParams) {
            const successMessage = "Visits were successfully exported";
            openInvoicesProcessorModal(getModalDataToExportByInvoiceAs, visitIds, extraParams, successMessage, onAllProcessesCompletedAction);
        },

        openInvoicesExporterModalByInvoices: function (invoiceIds, onAllProcessesCompletedAction) {
            const successMessage = "The selected invoices were exported successfully";
            openInvoicesProcessorModal(getModalDataToExportByInvoices, invoiceIds, null, successMessage, onAllProcessesCompletedAction);
        },

        openRecheckPendingInvoicesModalByInvoices: function (invoiceIds, onAllProcessesCompletedAction) {
            const successMessage = "The selected invoices were exported successfully";
            openInvoicesProcessorModal(getModalDataToRecheckPendingByInvoices, invoiceIds, null, successMessage, onAllProcessesCompletedAction);
        },
    };

    return invoicesProcessor;

};
