import { DateTimeFormatter, LocalDate, nativeJs } from "js-joda";
import phoneUtils from "../utils/phoneUtils";

//! @ngInject
export function patientsCtrl(
  GoogleAddressService,
  $http,
  $scope,
  $rootScope,
  $stateParams,
  NgTableParams,
  $state,
  DatabaseApi,
  toaster,
  $filter,
  Consts,
  $window,
  Storage,
  $uibModal,
  entityNotesModalService,
  $timeout,
  walkthroughsService,
  officesService,
  FilterUtils,
  wildcard,
  allergyService,
  TimeZoneService,
  generalUtils,
  agencyPatientStatusService,
  mfModal,
) {

  $scope.caregiversMap = DatabaseApi.caregivers();

  $window.scrollTo(0, 0);
  $scope.agencyCustomFields = [];
  $scope.languages = [
    "Albanian",
    "Arabic",
    "Armenian",
    "Bengali",
    "Bulgarian",
    "Cantonese",
    "Chinese",
    "Creole",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Farsi",
    "Filipino",
    "French",
    "Fukkianese",
    "Fula/Fulani",
    "Fuzhounese",
    "Georgian",
    "German",
    "Ghana",
    "Greek",
    "Hakka",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Italian",
    "Japanese",
    "Korean",
    "Krio",
    "Kyrgyz",
    "Mandarin",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Romanian",
    "Russian",
    "Shanghainese",
    "Sign",
    "Soninke",
    "Spanish",
    "Swahili",
    "Tagalog",
    "Taishanese",
    "Taiwanese",
    "Turkish",
    "Twi",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Yiddish"
  ];

  $scope.statuses = [];
  $scope.status = {};

  $scope.dropdownSettings = {
    styleActive: true,
    scrollable: true,
    scrollableHeight: "250px",
    enableSearch: true,
  };
  $scope.filters = {
    offices: undefined,
    teams: [],
    coordinators: [],
    agencyBranches: [],
    statuses: [],
    contracts: []
  };
  $scope.agencyTeamsOptions = [];
  $scope.agencyMembersOptions = [];
  $scope.dataOptionsInitialized = false;
  $scope.selectedContractType = {}

  $scope.getOfficeName = function (officeId) {
    if (!$scope.offices) return;

    const office = $scope.offices.find(({ id }) => officeId === id);
    return office ? office.label : "No office";
  };

  $scope.showPatientAgencyBranches = function (branchIds) {
    return branchIds
      .map((branchId) =>
        $scope.agencyBranches.find((branch) => branch.id === branchId)
      )
      .filter((branch) => branch !== undefined)
      .map((branch) => branch.label)
      .join(", ");
  };

  var savedTableArr = [];
  var tempPatients = [];

  var columns = Storage.getObject('patientsTableSettingsArray');

  if (columns && columns.length) {
    $scope.tableColumns = fixCachedColumns(columns);
  } else {
    $scope.tableColumns = [
      { field: "Last Name", title: "Last Name", togglable: true, show: true, filter: { lastName: "text" }, sortable: "lastName" },
      { field: "First Name", title: "First Name", togglable: true, show: true, filter: { firstName: "text" }, sortable: "firstName" },
      { field: "Actions", title: "Notes", togglable: false, show: true },
      { field: "displayId", title: "ID", togglable: false, show: true, filter: { displayId: "text" }, sortable: "displayId" },      
      { field: "Address", title: "Address", togglable: true, show: true, filter: { address: "text" }, sortable: "address" },
      { field: "Home Phone Number", title: "Home Phone", togglable: true, show: false, sortable: "homePhoneNumber" },
      { field: "Mobile Phone Number", title: "Mobile Phone", togglable: true, show: false, filter: { mobilePhoneNumber: "text" }, sortable: "mobilePhoneNumber" },
      { field: "Birthdate", title: "Birthdate", togglable: true, show: false, sortable: "birthdate" },      
      { field: "Office", title: "Office", togglable: true, show: true, sortable: "currentOfficeId" },
      { field: "Contract", title: "Contract", togglable: true, show: true, sortable: "contracts" },
      { field: "Medicaid Numbers", title: "Medicaid Numbers", togglable: true, show: true, sortable: "contractMedicaidNumbers" },
      { field: "Member Ids", title: "Member Ids", togglable: true, show: true, sortable: "contractMemberIds" },
      { field: "Start Of Care", title: "Start Of Care", togglable: true, show: false, sortable: "startOfCare" },
      { field: "Team", title: "Team", togglable: true, show: false, sortable: "agencyTeamId" },
      { field: "Assigned Coordinator", title: "Coordinator", togglable: true, show: false, sortable: "assignedCoordinator" },
      { field: "Status", title: "Status", togglable: true, show: true, sortable: "status" },
      { field: "Effective Date", title: "Effective Date", togglable: true, show: true, sortable: "effectiveDate" },
      { field: "Created At", title: "Created At", togglable: true, show: true, sortable: "createdAt" },
      { field: "Created By", title: "Created By", togglable: true, show: true, sortable: "createdBy" }
    ];
  }

  function fixCachedColumns(columns) {
    let firstNameColumnIndex, lastNameColumnIndex, startOfCareColumnIndex, effectiveDateIndex, memberIdsIndex, statusIndex, createdAtIndex, createdByIndex;
    let fixedColumns = columns;

    fixedColumns.forEach(function (column, i) {
      switch (column.field) {
        case "First Name":
          firstNameColumnIndex = i;
          if (column.filter === undefined) {
            column.filter = { firstName: "text" };
          }
          break;
        case "Last Name":
          lastNameColumnIndex = i;
          if (column.filter === undefined) {
            column.filter = { lastName: "text" };
          }
          break;
        case "Actions":
          column.title = "Notes";
          break;
        case "displayId":
          if (column.show === undefined) {
            column.show = true;
          }
          break;
        case "Start Of Care":
          startOfCareColumnIndex = i;
          break;
        case "Effective Date":
          effectiveDateIndex = i;
          break;
        case "Created At":
          createdAtIndex = i;
          break;
        case "Created By":
          createdByIndex = i;
          break;
        case "Status":
          statusIndex = i;
          break;
        default:
          break;
      }
    });

    fixedColumns = fixedColumns.filter(column => !["Initials", "Name"].includes(column.field));

    if (startOfCareColumnIndex === undefined) {
      fixedColumns.push({ field: "Start Of Care", title: "Start Of Care", togglable: true, show: false, sortable: "startOfCare" });
    }

    if (effectiveDateIndex === undefined) {
      fixedColumns.splice(statusIndex + 1, 0, { field: "Effective Date", title: "Effective Date", togglable: true, show: true, sortable: "effectiveDate" });
    }

    if (lastNameColumnIndex === undefined) {
      fixedColumns.splice(0, 0, { field: "Last Name", title: "Last Name", togglable: true, show: true, sortable: "lastName" });
    }

    if (firstNameColumnIndex === undefined) {
      fixedColumns.splice(1, 0, { field: "First Name", title: "First Name", togglable: true, show: true, sortable: "firstName" });
    }

    // Move Status, Effective Date, Created At, Created By to the end of the table
    if (createdAtIndex === undefined || createdAtIndex < statusIndex) {
      const newStatusIndex = fixedColumns.findIndex(column => column.field === "Status");
      fixedColumns.push(fixedColumns.splice(newStatusIndex, 1)[0]);
      const newEffectiveDateIndex = fixedColumns.findIndex(column => column.field === "Effective Date");
      fixedColumns.push(fixedColumns.splice(newEffectiveDateIndex, 1)[0]);
      const newCreatedAtIndex = fixedColumns.findIndex(column => column.field === "Created At");
      fixedColumns.push(fixedColumns.splice(newCreatedAtIndex, 1)[0]);
      fixedColumns.push({ field: "Created By", title: "Created By", togglable: true, show: true, sortable: "createdBy" });
    }

    if (lastNameColumnIndex !== undefined && firstNameColumnIndex !== undefined && firstNameColumnIndex < lastNameColumnIndex) {
      let lastNameColumn = {};
      for (
        let idx = 0;
        lastNameColumn.index === undefined && idx < fixedColumns.length;
        idx++
      ) {
        const currentColumn = fixedColumns[idx];
        if (currentColumn.field === "Last Name") {
          lastNameColumn = {
            index: idx,
            value: currentColumn
          }
        }
      }
      if (lastNameColumn.index !== undefined && lastNameColumn.value !== undefined) {
        fixedColumns.splice(lastNameColumn.index, 1);
        fixedColumns.splice(0, 0, lastNameColumn.value);
      }
    }

    return fixedColumns;
  }

  function initCustomFieldTableColumns() {
    if ($scope.agencyCustomFields.length) {
      for (const customField of $scope.agencyCustomFields) {
        // Add agency custom field to table column when not exists..
        if (customField.addToSettings && $scope.tableColumns.find((column) => column.field === "customField" && column.title === customField.fieldName) === undefined) {
          $scope.tableColumns.push({ field: "customField", title: customField.fieldName, show: false, togglable: true, sortable: `customFields.${[customField.fieldName]}` });
        }
      }
    }
  }

  $scope.$watch('tableColumns', function () {
    if ($scope.tableColumns) {
      Storage.setObject('patientsTableSettingsArray', $scope.tableColumns);
      initPatientTable(savedTableArr);
    }
  }, true);

  $scope.gradients = DatabaseApi.getCssGradient;

  $scope.newPatient = {
    ssn: null,
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    dateOfBirth: null,
    homePhoneNumber: null,
    mobilePhoneNumber: null,
    phone3: null,
    phone4: null,
    address: null,
    address2: null,
    generalNotes: null,
    medicalNotes: null,
    medicareNumber: null,
    medicaidNumber: null,
    wageParity: null,
    wageParityNumber: null,
    mainLanguage: null,
    secondaryLanguage: null,
    preferredHHAGender: null,
    smoking: null,
    pets: null,
    kosherHome: null,
    kidsInHouse: null,
    familyInformation: null,
    emergencyContact: [],
    priorityCode: null,
    evacuationZone: null,
    mobilityStatus: null,
    evacuationLocation: null,
    electricEquipmentDependency: {},
    allergies: null,
    patientHIClaim: null,
    email: null,
    source: null,
    socialWorker: null,
    assignedSalesRep: null,
    assignedIntake: null,
    assignedCoordinator: null,
    agencyTeamId: null,
    assignedDPS: null,
    assignedRN: null,
    admissionId: null,
    agencyPatientId: null,
    status: 'ELIGIBLE',
    startOfCare: null
  };

  $scope.mobilityStatuses = [
    { key: 'TAL 1: Bedbound', title: 'TAL1 - Bedbound' },
    { key: 'TAL 2: Chair Bound', title: 'TAL2- Chairbound' },
    { key: 'TAL 2: Ambulatory with assist', title: 'TAL2 - Ambulatory with assist' },
    { key: 'TAL 3: Ambulatory Independent', title: 'TAL3 - Ambulatory Independent' },
  ];

  $scope.goToPatient = function (id) {
    if (!$rootScope.user.permissions.includes("view_patient_profile")) {
      toaster.pop('warning','No permission to see patient\'s profile');
      return;
    }


    $rootScope.openPatientModal(id);
  };



  $scope.addNewPatientForm = {};

  $scope.cal = { birthDate: false };

  $scope.getTeamName = function (teamId) {
    if (!$scope.agencyTeams) return;

    const team = $scope.agencyTeams.find(({ id }) => teamId === id);
    return team ? team.name : "";
  };

  $scope.dislayNullableArray = (nullableArray, message) => {
    let displayText;

    if (nullableArray === null) {
      displayText = message;
    } else {
      displayText = nullableArray.join(", ");
    }

    return displayText;
  }

  $scope.setBirthDate = function (date) {
    if (date) {

      var localDateObj = LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
      //$scope.newPatient.dateOfBirth = localDateObj.format(DateTimeFormatter.ofPattern("MM/dd/yyyy"));
      $scope.newPatient.dateOfBirth = localDateObj.format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));

    } else {
      $scope.newPatient.dateOfBirth = null;
    }
  };

  $scope.setPhone = (phone, name, emergencyContactIndex) => {
    let val = (phone === undefined || phone === "") ? null : angular.copy(phone);
    if (val) {
      val = "+1" + val.replace(/\+1/g, "");
    }

    if (emergencyContactIndex === undefined) {
      $scope.newPatient[name] = val;
    } else {
      $scope.newPatient.emergencyContact[emergencyContactIndex][name] = val;
    }
  };

  $scope.formUpdated = function () {
    $scope.errorSocialWorker = '';
    $scope.errorSource = '';
    $scope.errors = '';
    Object.values($scope.addNewPatientForm).forEach(a => {
      a.$invalid = undefined
    });
  };

  $scope.handleChangeAddressLine1 = () => {
    $scope.formUpdated();
    $scope.setAddress($scope.newPatient.addressObject)
  }

  $scope.setAddress = async function (address) {
    if (address) {
      try {
        const components = typeof address === "string"
            ? await GoogleAddressService.getAddressComponentsFromText(address)
            : await GoogleAddressService.getAddressComponentsFromMedflytGeoCode(address);

        $scope.newPatient.address = {
          text: components.formatedAddressDetails.fullAddress,
          components: components
        };

        const timezoneResponse = await TimeZoneService.getTimzone(components.location);
        if (timezoneResponse) {
          const timezone = timezoneResponse.timeZoneId;
          $scope.newPatient.address.timezone = timezone;
        } else {
          toaster.pop("cant get timezone from location");
        }
      }
      catch (e) {
        toaster.pop('error', 'Invalid address: ' + e.message);
      }
    } else {
      $scope.newPatient.address = null;
    }
  };

  $scope.saleReps = [];
  initAgencyMembers();

  $scope.reload = function () {
    $state.reload();
  };


  //////////////////////
  //// get all data
  //////////////////////

  $scope.sourceTypes = [];
  $scope.referralSources = [];
  $scope.socialWorkers = [];

  // get source types
  function getReferralSourceTypes() {
    $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/referral_source/types').then(function (res) {
      $scope.sourceTypes = res.data.types;
    }, function (err) {
      toaster.pop('error', "Something went wrong", "could not get source types");
      $scope.sourceTypes = [];
    });
  }
  // getReferralSourceTypes()

  // get sources
  function getReferralSources(cb) {
    $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/referral_sources').then(function (res) {
      $scope.referralSources = res.data.sources;
    }, function (err) {
      toaster.pop('error', "Something went wrong", "could not get referral sources");
    });
  }
  // getReferralSources();


  // get social workers
  function getSocialWorkers() {
    $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/social_workers').then(function (res) {
      $scope.socialWorkers = res.data.workers;
    }, function (err) {
      toaster.pop('error', "Something went wrong", "could not get social workers");
    });
  }
  // getSocialWorkers();


  $scope.referralCount = 0;

  /////////////////////////
  //// patient table
  /////////////////////////
  function initPatientTable(data) {
    var filters = undefined, sorting = { createdAt: "desc" };
    if ($scope.table) {
      filters = $scope.table.filter();
      sorting = $scope.table.sorting();
    }
    var options = {
      count: 25,
      sorting: sorting
    };

    if (filters) options.filter = filters;

    $scope.table = new NgTableParams(options, {
      counts: [],
      dataset: data
    });

    // Removed DEC 2020 due to new notes feature
    // getWalkthroughs();

    // Load filters
    const storageFilters = FilterUtils.rootFilters.patientsCtrl;
    if (storageFilters !== undefined) {
      $scope.filters = storageFilters;
    }
  }

  $scope.$on("$destroy", function () {
    const filters = $scope.filters;
    FilterUtils.rootFilters.patientsCtrl = angular.copy(filters);
  });

  /////////////////////////
  //// new referral source
  /////////////////////////

  $scope.newReferralSource = {};

  $scope.submitAndSelectNewReferralSource = function () {

    var newReferralSource = angular.copy($scope.newReferralSource);

    newReferralSource.address = newReferralSource.address ? newReferralSource.address.formatted_address : null;

    if (!newReferralSource.type)
      return $scope.errorSource = 'Please pick source type';
    else if (!newReferralSource.displayName)
      return $scope.errorSource = 'Please enter name';
    else if (!newReferralSource.phoneNumber || !phoneUtils.isValidNumber('+1' + newReferralSource.phoneNumber))
      return $scope.errorSource = 'Missing or invalid phone number';
    else if (!newReferralSource.address)
      return $scope.errorSource = 'Missing Address';
    else
      $scope.errorSource = '';

    newReferralSource.phoneNumber = '+1' + newReferralSource.phoneNumber;

    $http.put(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/referral_source', newReferralSource).then(function (res) {
      toaster.pop('success', "New referral source added");
      $scope.referralSources.push(res.data);
      $scope.newPatient.sourceObject = res.data.id.toString();
      $scope.newReferralSource = {};
    }, function (err) {
      toaster.pop('error', "Something went wrong", "could not add source");
    });

  };

  /////////////////////////
  //// new social worker
  /////////////////////////

  $scope.newSocialWorker = {};

  $scope.submitAndSelectNewNewSocialWorker = function () {

    var newSocialWorker = angular.copy($scope.newSocialWorker);

    if (!newSocialWorker.firstName)
      return $scope.errorSocialWorker = 'Please enter first name';
    if (!newSocialWorker.lastName)
      return $scope.errorSocialWorker = 'Please enter last name';
    else if (!newSocialWorker.email)
      return $scope.errorSocialWorker = 'Missing or invalid email';
    else if (!newSocialWorker.phoneNumber || !phoneUtils.isValidNumber('+1' + newSocialWorker.phoneNumber))
      return $scope.errorSocialWorker = 'Missing or invalid phone number';
    else
      $scope.errorSocialWorker = '';

    newSocialWorker.phoneNumber = '+1' + newSocialWorker.phoneNumber;

    $http.put(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/social_worker', newSocialWorker).then(function (res) {
      //toaster.pop('success', "New Social Worker Added");
      $scope.socialWorkers.push(res.data);
      $scope.newPatient.socialWrokerObject = res.data.id.toString();
      $scope.newSocialWorker = {};
    }, function (err) {
      toaster.pop('error', "Something went wrong", "could not add social worker");
    });

  };


  /////////////////////////
  //// new patient
  /////////////////////////

  $scope.newPatientShow = false;
  $scope.showNewPatientForm = function () {
    $scope.newPatientShow = !$scope.newPatientShow;

    const selectOnlyOffice = () => {
      if ($scope.newPatient.office) return;
      if (!$scope.newPatientOffices) return;

      const office = $scope.newPatientOffices[0];
      $scope.newPatient.office = {
        id: office.id,
        label: office.label,
      };
    };

    const newPatientOffices = (offices) =>
      offices
        .filter((office) => office.active)
        .filter((office) => {
          if ($rootScope.user.agencyMember.officeIds) {
            return (
              $rootScope.user.agencyMember.officeIds.find(
                (memberOfficeId) => memberOfficeId === office.id
              ) !== undefined
            );
          }
          return true;
        })
        .map((office) => ({
          id: office.id,
          label: office.name,
        }));

    if (!officesService.offices) {
      officesService.getOffices().then((offices) => {
        $scope.newPatientOffices = newPatientOffices(offices);
        selectOnlyOffice();
      });
    } else {
      $scope.newPatientOffices = newPatientOffices(officesService.offices);
      selectOnlyOffice();
    }
    $timeout(function () {
      window.scrollTo(0, 0);
    }, 1);
  };

    if ($stateParams.createPatientParams) {
        $scope.showNewPatientForm();
        const form = Object.entries(angular.copy($stateParams.createPatientParams.form))
            .filter(([, value]) => value !== undefined)
            .reduce((acc, [key, value]) => { acc[key] = value; return acc; }, {});

        $scope.newPatient = {
            ...$scope.newPatient,
            ...form
        };

        if ($scope.newPatient.homePhoneNumberForm) {
            $scope.setPhone($scope.newPatient.homePhoneNumberForm, "homePhoneNumber");
        }

        if ($scope.newPatient.mobilePhoneNumberForm) {
            $scope.setPhone($scope.newPatient.mobilePhoneNumberForm, "mobilePhoneNumber");
        }

        if ($scope.newPatient.phone3Form) {
            $scope.setPhone($scope.newPatient.mobilePhoneNumberForm, "phone3");
        }

        if ($scope.newPatient.addressObject !== null) {
            $scope.handleChangeAddressLine1();
        }

        if ($scope.newPatient.status !== null) {
            $scope.$watch("statuses", () => {
                const status = $scope.statuses.find((x) => x.label === $scope.newPatient.status);

                if (status) {
                    $scope.status = status;
                }
            });
        }

        const { contract } = $stateParams.createPatientParams;

        $scope.$watch("shouldCreateContract", () => {
            if (!$scope.shouldCreateContract) {
                $scope.newPatient.contract = undefined;
                $scope.formUpdated();
            }
        });

        if (contract.payerId) {
            $scope.canCreateContract = true;
            $scope.shouldCreateContract = true;
            $scope.payerContracts = DatabaseApi
              .contractTypes()
              .filter(x => x.payer === contract.payerId)
              .map(x => ({ id: x.id, label: x.name }));

            $scope.newPatient.contract = {
                isPrimary: contract.isPrimary,
                medicareNumber: null,
                medicaidNumber: contract.medicaidNumber ?? null,
                memberId: contract.memberId ?? null
            };

            $scope.$watch("selectedContractType", () => {
                if ($scope.selectedContractType.id) {
                    $scope.newPatient.contract.contractType = $scope.selectedContractType.id;
                    $scope.formUpdated();
                }
            });
        }

        $scope.formUpdated();
    }

  $scope.allergies = [];

  if (!allergyService.allergies) {
    allergyService.getAllergies($rootScope.agencyId).then((allergies) => {
      $scope.allergyTypes = allergies.map(item => ({
        id: item.id,
        label: item.name
      }));
    });
  } else {
    $scope.allergyTypes = allergyService.allergies.map(item => ({
      id: item.id,
      label: item.name
    }));
  }

  $scope.selectAllergiesExtraSettings = {
    styleActive: true,
    scrollable: true,
    scrollableHeight: '330px',
    enableSearch: true
  };

  $scope.selectStatusesExtraSettings = {
    showCheckAll: false,
    showUncheckAll: false,
    styleActive: true,
    scrollable: true,
    scrollableHeight: '330px',
    enableSearch: true
  }

  $scope.selectContractTypesExtraSettings = {
    showCheckAll: false,
    showUncheckAll: false,
    styleActive: true,
    scrollable: true,
    enableSearch: true
  }

  $scope.newPatientAddEmergencyContact = () => {
    $scope.newPatient.emergencyContact.push({
      firstName: "",
      lastName: "",
      relationship: "",
      livesWithPatient: undefined,
      emergencyPhoneNumber1: "",
      emergencyPhoneNumber2: "",
      address: ""
    });
    $scope.formUpdated();
  };

  $scope.newPatientRemoveEmergencyContact = (idx) => {
    $scope.newPatient.emergencyContact.splice(idx, 1);
    $scope.formUpdated();
  }

  function validateNewPatientForm() {
    let errorMessages = [];
    $scope.addNewPatientForm = {};
    if (!$scope.newPatient.firstName || $scope.newPatient.firstName.trim() === "") {
      $scope.addNewPatientForm.firstName = { $invalid: true };
      errorMessages.push("Missing or invalid first name");
    }
    if (!$scope.newPatient.lastName || $scope.newPatient.lastName.trim() === "") {
      $scope.addNewPatientForm.lastName = { $invalid: true };
      errorMessages.push("Missing or invalid last name");
    }
    if (!$scope.validateNewPatientSsn($scope.newPatient.ssn)) {
      $scope.addNewPatientForm.ssn = { $invalid: true };
      errorMessages.push("Missing or invalid SSN");
    }
    if (!$scope.newPatient.gender) {
      $scope.addNewPatientForm.gender = { $invalid: true };
      errorMessages.push("Missing gender");
    }
    if (!$scope.newPatient.address || !$scope.newPatient.address.text) {
      $scope.addNewPatientForm.address = { $invalid: true };
      errorMessages.push("Missing address");
    }
    ($scope.newPatient.emergencyContact || []).forEach((contact, index) => {
      const indexPart = $scope.newPatient.emergencyContact.length > 1 ? ` #${index + 1}` : ``;

      if (!contact.firstName || contact.firstName.trim() === "") {
        $scope.addNewPatientForm[`emergencyContact-${index}-firstName`] = { $invalid: true };
        errorMessages.push(`Missing or invalid emergency contact${indexPart} first name`);
      }
      if (!contact.lastName || contact.lastName.trim() === "") {
        $scope.addNewPatientForm[`emergencyContact-${index}-lastName`] = { $invalid: true };
        errorMessages.push(`Missing or invalid emergency contact${indexPart} last name`);
      }
      if (
        [null, undefined, "", "+1"].indexOf(contact.emergencyPhoneNumber1) !== -1 ||
        contact.emergencyPhoneNumber1.trim() === "" ||
        !phoneUtils.isValidNumber(contact.emergencyPhoneNumber1)
      ) {
        $scope.addNewPatientForm[`emergencyContact-${index}-phone1`] = { $invalid: true };
        errorMessages.push(`Missing or invalid emergency contact${indexPart} phone #1`);
      }
      if (
        [null, undefined, "", "+1"].indexOf(contact.emergencyPhoneNumber2) !== -1 &&
        contact.emergencyPhoneNumber2.trim() !== "" &&
        !phoneUtils.isValidNumber(emergencyContact.emergencyPhoneNumber2)
      ) {
        $scope.addNewPatientForm[`emergencyContact-${index}-phone2`] = { $invalid: true };
        errorMessages.push(`Invalid emergency contact${indexPart} phone #2`);
      }
      if (contact.livesWithPatient === undefined) {
        $scope.addNewPatientForm[`emergencyContact-${index}-livesWithPatient`] = { $invalid: true };
        errorMessages.push(`Missing emergency contact${indexPart} lives with patient`);
      }
    });

    const setInvalid = (property, _errorMessage, scope) => {
        const invalid = { $invalid: true };
        scope !== undefined
            ? $scope.addNewPatientForm[scope] = {
                ...$scope.addNewPatientForm[scope],
                ...invalid,
                [property]: invalid
            }
            : $scope.addNewPatientForm[property] = invalid;
        errorMessages.push(_errorMessage);
    }

    const validateRequired = (property, _errorMessage, scope) => {
        const value = scope !== undefined ? $scope.newPatient[scope][property] : $scope.newPatient[property];

        if (value === null || value === undefined) {
            setInvalid(property, _errorMessage, scope);
        }
    }

    const validateFn = (property, validateFn, _errorMessage, scope) => {
        const value = scope !== undefined ? $scope.newPatient[scope][property] : $scope.newPatient[property];

        if (value === null || value === undefined) {
            return;
        }

        if (!validateFn(value)) {
            setInvalid(property, _errorMessage, scope);
        }
    }

    if ($scope.shouldCreateContract) {
        validateRequired("contractType", "Contract type is required", "contract");
        validateRequired("startDate", "Contract start date is required", "contract");
        validateFn(
            "medicaidNumber",
            x => generalUtils.isMedicaidNumberValid(x, $scope.newPatient.address.components.formatedAddressDetails.state),
            "Invalid Medicaid pattern",
            "contract"
        );
    }
    return errorMessages;
  }

  $scope.submitNewPatient = function () {
    if ($scope.sending) return;

    if ($scope.selectedContractType.id) {
        $scope.newPatient.contract.contractType = $scope.selectedContractType.id;
    }

    const errorMessages = validateNewPatientForm();
    if (
      !$scope.addNewPatientForm ||
      $scope.addNewPatientForm.$invalid ||
      Object.values($scope.addNewPatientForm).find(a => a.$invalid)
    ) {
      if (errorMessages && errorMessages.length > 0) {
        const modal = mfModal.create({
          variant: "danger",
          subject: "Error",
          message: errorMessages.join("\n"),
          layoutOrder: ["message"],
          confirmLabel: "Ok",
          hideCancelButton: true,
          onComplete: () => modal.close()
        });
      }
      return;
    };

    $scope.newPatient.currentOfficeId = $scope.newPatient.office.id;
    $scope.newPatient.allergies = $scope.allergies.map(item => item.id);
    if ($scope.status.id) {
        const status = $scope.statuses.find(x => x.id === $scope.status.id);
        $scope.newPatient.status = status ? status.value : "ELIGIBLE";
    }
    var phoneNumber = $scope.newPatient.mobilePhoneNumber !== null ? $scope.newPatient.mobilePhoneNumber : $scope.newPatient.homePhoneNumber;
    var identifiers = {
      firstName: $scope.newPatient.firstName,
      lastName: $scope.newPatient.lastName,
      address: $scope.newPatient.address.text,
      phoneNumber: phoneNumber,
      currentOfficeId: $scope.newPatient.office.id,
      admissionId: null,
      ssn: $scope.newPatient.ssn,
      dateOfBirth: $scope.newPatient.dateOfBirth
    };

    const potentialPatientsUrl = "agencies/:agencyId/patients/potential_patients"
      .replace(":agencyId", $rootScope.agencyId);
    DatabaseApi.post(potentialPatientsUrl, identifiers).then((res) => {
      if (res.data.patients.length === 0) {
        addPatient()
      } else {
        var possibleMatchesModal = $uibModal.open({
          templateUrl: 'admin/views/patients-pre-existing-modal.html',
          size: 'lg',
          controller: 'patientsPreExistingModalCtrl',

          resolve: {
            possibleMatches: function () { return res.data; },
          }
        });
        possibleMatchesModal.result.then(function (res) {
          addPatient();
        }, function () {
          //dismissed - do nothing
        });
      }
    }, function (err) {
      toaster.pop('error', "Something went wrong", "could not check for existing patients");
    });
  };

  function isEmergencyContactValid(data) {
    return (
      data.firstName &&
      data.firstName.trim() !== "" &&
      data.lastName &&
      data.lastName.trim() !== "" &&
      data.emergencyPhoneNumber1 &&
      data.emergencyPhoneNumber1.trim() !== "" &&
      data.livesWithPatient !== undefined
    );
  }

  function fmap(target, predicate) {
    if (target === null || target === undefined) {
        return target;
    }

    return predicate(target);
  }

  function getEmergencyContacts(emergencyContacts) {
    if (emergencyContacts.length === 0) {
      return null;
    }
    if (emergencyContacts.find(contact => !isEmergencyContactValid(contact))) {
      return { errorMessage: "Invalid emergency contacts" };
    }
    emergencyContacts.forEach(contact => {
      delete contact.emergencyPhoneNumber1Form;
      delete contact.emergencyPhoneNumber2Form;
    })
    return emergencyContacts;
  }

  function addPatient() {
    $scope.sending = true;

    const body = angular.copy($scope.newPatient);

    if (body.contract) {
        body.contract.startDate = LocalDate.from(nativeJs(body.contract.startDate));
        body.contract.startOfCareDate = fmap(body.contract.startOfCareDate, x => LocalDate.from(nativeJs(x)));
    }

    body.emergencyContact = getEmergencyContacts(body.emergencyContact);
    if (body.emergencyContact && body.emergencyContact.errorMessage) {
      toaster.pop("error", body.emergencyContact.errorMessage)
      $scope.sending = false;
      return;
    }
    body.priorityCode = $scope.newPatient.priorityCode ? parseInt($scope.newPatient.priorityCode) : null
    const dependencies = Object.entries($scope.newPatient.electricEquipmentDependency)
      .filter(entry => entry[1])
      .map(entry => entry[0])
      .join(",");
    body.electricEquipmentDependency = (dependencies === "") ? null : dependencies;

    const url = `agencies/:agencyId/patients_referral`.replace(":agencyId", $rootScope.agencyId);

    DatabaseApi.post(url, body).then(({ data }) => {
      // Hide new patient form + reset form
      $state.go('app.patients.dashboard', {}, { reload: true });
      window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}?patient=${data.id}`;
    }, function (err) {
      toaster.pop('error', "Something went wrong", "could not add patient");
      $scope.sending = false;
    });
  }

  $scope.validateNewPatientSsn = (ssn) => {
    const isInvalid = !!ssn && !generalUtils.isSsnValid(ssn);

    if (isInvalid) {
        $scope.addNewPatientForm.ssn = { $invalid: true };
    }

    return !isInvalid;
  };

  /*

      $scope.applyGlobalSearch = function (term) {
          var filter = { $: term };
          if ($scope.table) {
              angular.extend($scope.table.filter(), filter);
          } else {
              console.log('no table');
          }
      };
    */

  $scope.exportTable = function () {
    const rows = [];
    const titles = [];
    for (const { field, title, show } of $scope.tableColumns) {
      if (show && !["Initials", "Actions"].includes(field)) {
        titles.push(title);
      }
    }
    rows.push(["Name"].concat(titles));
    for (const patient of savedTableArr) {
      const row = [];
      const customFieldNames = Object.keys(patient.customFields);
      row.push('"' + patient.displayName + '"');

      for (const title of titles) {
        if (customFieldNames.includes(title)) {
          const customField = patient.customFields[title];
          if (customField !== undefined){
            switch(customField.fieldType){
              case "PATIENT" :
                row.push('"' + customField.value.displayName + '"');
                break;
              case "CAREGIVER" :
                row.push('"' + customField.value.displayName + '"');
                break;
              case "DATE_RANGE" :
                row.push('"' + $filter("mfShortDate")(customField.from) + ' - ' + $filter("mfShortDate")(customField.to) + '"');
                break;
              case "DATE" :
                row.push('"' + $filter("mfShortDate")(customField.value) + '"');
                break;
              case "PHONE_NUMBER" :
                row.push('"' + customField.value.replace('+1','') + '"');
                break;
              case "BOOLEAN" :
                row.push('"' + (customField.value === true ? 'Yes' : 'No') + '"');
                break;
              default:
                row.push('"' + customField.value + '"');
            }
          }
          else {
            row.push('" "');
          }
        }
        else {
          switch (title) {
            case "First Name":
              row.push('"' + patient.firstName + '"');
              break;
            case "Last Name":
              row.push('"' + patient.lastName + '"');
              break;
            case "ID":
              row.push(patient && patient.displayId ? '"' + patient.displayId.toString() + '"' : '" "');
              break;
            case "Created At":
              row.push($filter("mfShortTime")(patient.createdAt, ["withDate"]) || "");
              break;
            case "Created By":
              row.push(('"' + patient.createdByName + '"') || "");
              break;
            case "Address":
              if (patient && patient.address) {
                row.push('"' + patient.address.replace("#", "").replace('"', '') + '"' || '" "');
              } else {
                row.push('" "');
              }
              break;
            case "Status":
              row.push(patient.status || "");
              break;
            case "Effective Date":
              row.push($filter("mfShortDate")(patient.effectiveDate) || "");
              break;
            case "Office":
              row.push((patient.currentOfficeId ? $scope.getOfficeName(patient.currentOfficeId) : "") || "");
              break;
            case "Contract":
              if (patient && patient.contracts && Array.isArray(patient.contracts)) {
                row.push('"' + patient.contracts.join(", ") + '"');
              } else {
                row.push('" "');
              }
              break;
            case "Medicaid Numbers":
              if (patient && patient.contractMedicaidNumbers && Array.isArray(patient.contractMedicaidNumbers)) {
                row.push('"' + patient.contractMedicaidNumbers.join(", ") + '"');
              } else {
                row.push('" "');
              }
              break;
            case "Member Ids":
              if (patient && patient.contractMemberIds && Array.isArray(patient.contractMemberIds)) {
                row.push('"' + patient.contractMemberIds.join(", ") + '"');
              } else {
                row.push('" "');
              }
              break;
            case "Team":
              row.push((patient.agencyTeamId ? $scope.getTeamName(patient.agencyTeamId) : "") || "");
              break;
            case "Coordinator":
              row.push(
                (patient.assignedCoordinator ?
                  '"' + $scope.agencyMembersMap[patient.assignedCoordinator] + '"' :
                  '" "'
                ) || '" "');
              break;
            case "Home Phone":
              row.push(patient.homePhoneNumber || "");
              break;
            case "Mobile Phone":
              row.push(patient.mobilePhoneNumber || "");
              break;
            case "Birthdate":
              row.push($filter("mfShortDate")(patient.dateOfBirth) || "");
              break;
            case "Start Of Care":
              row.push($filter("mfShortDate")(patient.startOfCare) || "");
              break;
            default:
              row.push('" "');
              break;
          }
        }
      }

      rows.push(row);
    }

    let csvContent = "";
    rows.forEach(function (rowArray) {
      const row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    const prefix = "data:text/csv;charset=utf-8,";
    const encodedUri = prefix + encodeURIComponent(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "medflyt-patients-export.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  $scope.setFilter = function (filter) {
    if ($scope.filters.status === filter) {
      //$scope.table.filter({  });
      angular.extend($scope.table.filter(), { status: undefined });
      $scope.filters.status = undefined;
    } else {
      //$scope.table.filter({ status: filter });
      angular.extend($scope.table.filter(), { status: filter });
      $scope.filters.status = filter;
    }
  };


  $scope.filter = { coordinator: $rootScope.user.displayName };

  /*function getMemberIdFromName(name){
      for(var i = 0 ; i < coordinators.length ; i++){
          c = coordinators[i].firstName + ' ' + coordinators[i].lastName;
          if(name === c){
              filterByMemberId = coordinators[i].id;
              return coordinators[i].id;
          }
      }
      filterByMemberId = null;
      return false
  }*/

  function getStatusById(id) {
    const status = $scope.statuses.find((status) => id === status.id);
    return status && status.value;
  }

  $scope.globalFilter = { val: '' };
  $scope.filterByPatientName = function () {
    const filter = { $: $scope.globalFilter.val };

    if ($scope.table) {
      angular.extend($scope.table.filter(), filter);
    } else {
      console.log("no table");
    }
  };

  function getPatients() {
    if (!$scope.dataOptionsInitialized) return;
    let qs = '';

    if ($scope.filters.offices && $scope.filters.offices.length > 0) {
      qs += `&offices=${$scope.filters.offices
        .map(({ id }) => id)
        .join(",")}`;
    }

    if ($scope.filters.statuses && $scope.filters.statuses.length > 0) {
      qs += `&statuses=${$scope.filters.statuses
        .map(({ id }) => getStatusById(id))
        .join(",")}`;
    }
    if ($scope.filters.agencyBranches && $scope.filters.agencyBranches.length > 0) {
      qs += `&agencyBranches=${$scope.filters.agencyBranches
        .map(({ id }) => id)
        .join(",")}`;
    }
    if ($scope.filters.teams.length > 0) {
      qs += `&teams=${$scope.filters.teams
        .map(({ id }) => id)
        .join(",")}`;
    }
    if ($scope.filters.coordinators.length > 0) {
      qs += `&coordinators=${$scope.filters.coordinators
        .map(({ id }) => id)
        .join(",")}`;
    }
    if ($scope.filters.contracts.length > 0) {
      qs += `&contracts=${$scope.filters.contracts
        .map(({ id }) => id)
        .join(",")}`;
    }

    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/patients_page?' + qs).then(function (res) {
      $scope.referralCount = 0;

      savedTableArr = [];
      tempPatients = [];

      res.data.patients.forEach(function (patient) {
        if (patient.status === "REFERRAL") {
          $scope.referralCount++;
        }
        patient.statusClass = agencyPatientStatusService.getStatusClass(patient.status);
        patient.displayName = patient.middleName ?
          patient.firstName + ' ' + patient.middleName + ' ' + patient.lastName :
          patient.firstName + ' ' + patient.lastName;
        patient.nameFirstLast = patient.firstName + ' ' + patient.lastName;
        patient.nameLastFirst = patient.lastName + ' ' + patient.firstName;

        patient.createdByName = $scope.agencyMembersMap[patient.createdBy];

        tempPatients.push(Object.assign({}, patient));
        savedTableArr.push(Object.assign({}, patient));

      });

      $scope.patients = tempPatients;
      initPatientTable($scope.patients);

    }, function (err) {
        console.log('err', err);
      toaster.pop('error', "Something went wrong", "could not get patients");
      $scope.patients = [];
    });
  }

  $scope.capitalize = function (text) {
    if (!text) return '';
    var words = text.split(' ');
    var capitalized = '';
    words.forEach(function (word, index) {
      if (index > 0) {
        capitalized += ' ';
      }
      capitalized += word[0].toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalized;
  }


  //////////////////////////////
  //////// upload patients
  //////////////////////////////
  $scope.uploadPatients = function () {
    $scope.upload = true;
    getHistory();
  };

  $scope.mergePatients = function () {
    $state.go('app.mergePatients');
  };

  $scope.upload = false;
  $scope.file = null;
  var uploadsDoneCounter = 0;
  var uploadsErrors = [];

  function getHistory() {
    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/table_file_upload').then(function (response) {
      var history = [];
      response.data.list.forEach(function (line) {
        if (line.type === 'PATIENT') history.push(line);
      })
      $scope.history = history;

    }, function (response) {

    });
  }

  $scope.$watch('file', function () {

    if (!$scope.file) return;

    var isError = false;

    $scope.file.forEach(function (file) {
      if (file.name) {
        var fileType = file.name.split('.');
        fileType = fileType[fileType.length - 1];
        fileType = fileType.toLowerCase();
        if (fileType === 'xls' || fileType === 'xlsx') {

        } else {
          isError = true;
        }
      } else {
        isError = true;
      }
    });

    if (isError) {
      toaster.pop('warning', "Unsupported file type", 'Please uplaod an Excel file (.xls, .xlsx)');
      $scope.file = null;
      return;
    }

    uploadsDoneCounter = 0;
    uploadsErrors = [];

    $scope.file.forEach(function (file) {
      fileUpload(file);
    });

  });

  function fileUpload(file) {

    var formData = new FormData();
    formData.append('file', file, file.name);

    formData.append('type', 'PATIENT');
    formData.append('caregiverBranch', '');

    var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/table_file_upload';
    $http({
      url: url,
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': undefined }
    }).then(function (response) {
      doneUploadFile();

    }, function (response) {
      doneUploadFile(response);
    });

  }

  function doneUploadFile(err) {
    uploadsDoneCounter++;

    if (err) {
      uploadsErrors.push(err);
    }

    if (uploadsDoneCounter === $scope.file.length) {
      if (uploadsErrors.length) {
        var errText = uploadsErrors.length + ' uploads failed. Please try again';
        $scope.fileSent = 'An Error Occurred, please refresh the page and try again';
        toaster.pop('error', "Something Went Wrong", errText);
      } else {
        $scope.fileSent = 'Thanks for uploading, the file was uploaded successfully.';
        toaster.pop('success', "Success", "File Uploaded");
      }

      $scope.file = null;
      getHistory();

    }
  }

  $scope.exportTxt = function ({ filename, createdAt, message }) {
    const blob = new Blob([message], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}-${createdAt}-result.txt`);

    link.click();
    window.URL.revokeObjectURL(url);
  };

  $scope.downloadOriginalFile = function ({ id }) {
    const endpoint = wildcard(
      `agencies/:agencyId/table_file_upload/:uploadJobId/url`,
      $rootScope.agencyId,
      id
    );

    DatabaseApi.get(endpoint)
      .then(({ data: { url } }) => {
        window.open(url);
      })
      .catch(() => toaster.pop("error", "File wasn't found"));
  };

  $scope.handleNotesModalOpen = function (profileId, profileName) {
    const notesModalConfig = {
      profileId,
      profileName,
      notesType: 'Patient'
    };
    entityNotesModalService.handleNotesModalOpen(notesModalConfig);
  }

  async function getWalkthroughs() {
    walkthroughsService.handleWalkthroughs('patientsNotes');
  }

  async function initAgencyMembers() {
    $scope.agencyMembers = DatabaseApi.getAgencyMembersArr();
    $scope.agencyMembersMap = DatabaseApi.getAgencyMembers();
    if ($scope.agencyMembers.length === 0) {
      await DatabaseApi.loadAgencyMembers();
      $scope.agencyMembers = DatabaseApi.getAgencyMembersArr();
      $scope.agencyMembersMap = DatabaseApi.getAgencyMembers();
    }
    var saleRepJobTitles = ['SalesRep', 'SeniorSalesRep', 'HeadOfSales'];
    var saleReps = [];
    $scope.agencyMembers.forEach((member) => {
      if (!saleRepJobTitles.includes(member.jobTitle)) {
        saleReps.push({
          name: member.firstName + ' ' + member.lastName,
          id: member.id
        });
      }
    });
    $scope.saleReps = saleReps;
  }

  function initAgencyTeams() {
    $scope.agencyTeams = DatabaseApi.agencyTeams().filter(team => team.active);
    $scope.agencyTeamsOptions = $scope.agencyTeams.map((team) => {
      if (team.hasCasesNotDischarged) {
        return ({
          id: team.id,
          label: team.name,
        })
      }
    });
  }

  const initDataOptions = async () => {
    initAgencyMembers();
    initAgencyTeams();
    $scope.contractTypes = DatabaseApi.activeContractTypes();
    $scope.contractTypesOptions = $scope.contractTypes.map(contract => ({
      id: contract.id,
      label: contract.name,
    }));
    const agencyCustomFields = DatabaseApi.agencyCustomFields();
    $scope.agencyCustomFields = angular.copy(
      agencyCustomFields.filter(
        (customField) =>
          customField.addToSettings && customField.entityName === "PATIENT"
      )
    );
    initCustomFieldTableColumns();
    initCoordinatorsOptions();

    const setDefaultOffices = () => {
      // agency member offices added to default filters
      if ($rootScope.user.agencyMember.officeIds) {
        $scope.filters.offices = $rootScope.user.agencyMember.officeIds
          .map(function (officeId) {
            return $scope.offices.find(function (obj) {
              return obj.id === officeId;
            });
          })
          .filter((office) => office !== undefined);
      } else {
        $scope.filters.offices = [];
      }
      $scope.offices.unshift({ id: -1, label: "Inactive Offices" });
    };

    const relevantOffices = (offices) =>
      offices
        .filter((office) => office.active)
        .map((office) => ({
          id: office.id,
          label: office.name,
        }));


    const statuses = await agencyPatientStatusService.getAgencyPatientStatuses();

    $scope.statuses = statuses.map((status, i) => ({
        id: i + 1,
        value: status.text,
        label: (status.text + (status.active ? "" : " (N/A)")).replace(/_/g, " "),
        active: status.active
    })).sort((a, b) => {
      if (a.active && !b.active) { return -1;}
      if (!a.active && b.active) { return 1; }
      if (a.label < b.label) { return -1;}
      if (a.label > b.label) { return 1; }
      return 0;
    });
    $scope.filters.statuses = [
      $scope.statuses.find((status) => status.value === "ACTIVE")
    ];

    if (!officesService.offices) {
      await officesService.getOffices().then((offices) => {
        $scope.offices = relevantOffices(offices);
        setDefaultOffices();
      });
    } else {
      $scope.offices = relevantOffices(officesService.offices);
      setDefaultOffices();
    }
    $scope.dataOptionsInitialized = true;
    $scope.agencyPatientStatuses = DatabaseApi.agencyPatientStatuses();
    $scope.statuses = $scope.statuses.filter((status) => {
      const foundStatus = $scope.agencyPatientStatuses.find(
        (agencyStatus) => agencyStatus.text === status.value
      );
      return foundStatus !== undefined;
    });
    $scope.filters.statuses = $scope.filters.statuses.filter(
      (filterStatus) =>
        $scope.statuses.find(
          (status) => status.value === filterStatus.value
        ) !== undefined
    );
    const agencyBranches = DatabaseApi.agencyBranches() || [];
    $scope.agencyBranches = agencyBranches.map(branch => ({
      id: branch.id,
      label: branch.name
    }))
  };

  const initCoordinatorsOptions = () => {
    $scope.agencyMembersOptions = [];
    $scope.agencyMembersByRoles = DatabaseApi.getAgencyMembersByRole() || {};

    if ($scope.agencyMembersByRoles.coordinators) {
      $scope.agencyMembersByRoles.coordinators.forEach((coordinator) => {
        if (coordinator.status === "Active" && 
            (coordinator.officeIds.findIndex(officeId => $rootScope.user.agencyMember.officeIds.includes(officeId)) !== -1) &&
            coordinator.hasCasesNotDischarged) {
          $scope.agencyMembersOptions.push({
            id: coordinator.id,
            label: coordinator.displayName,
            displayName: coordinator.displayName,
          });
        }
      });
    }
    if ($scope.agencyMembersByRoles.coordinatorsSeniors) {
      $scope.agencyMembersByRoles.coordinatorsSeniors.forEach((coordinator) => {
        if ((coordinator.officeIds.findIndex(officeId => $rootScope.user.agencyMember.officeIds.includes(officeId)) !== -1) && coordinator.hasCasesNotDischarged) {
          $scope.agencyMembersOptions.push({
            id: coordinator.id,
            label: coordinator.displayName,
            displayName: coordinator.displayName,
          });
        }
      });
    }
    $scope.agencyMembersOptions = $scope.agencyMembersOptions.sort(generalUtils.sortByDisplayName);
  }

  initDataOptions().then(() =>
    $scope.$watch(
      "filters",
      function () {
        getPatients();
      },
      true
    )
  );

  $rootScope.$on("got_data", function () {
    if ($scope.dataOptionsInitialized) return;

    initDataOptions();
  });

  $rootScope.$on("got_caregivers_data", function (event) {
    $scope.caregiversMap = DatabaseApi.caregivers();
    initPatientTable(savedTableArr);
  });

  $rootScope.$on("got_agency_teams", function(event) {
    initAgencyTeams();
    initPatientTable(savedTableArr);
  })
};
