import moment from "moment";

//! @ngInject
export function caregiversComplianceCtrl(
    $scope,
    $rootScope,
    $filter,
    NgTableParams,
    Consts,
    $http,
    DatabaseApi,
    toaster,
    wildcard,
    $uibModal,
    entityNotesModalService,
    $timeout,
    $window,
    Storage,
    complianceService,
    complianceConsts,
) {
    const init = () => {
        initialized = true;
        initTableColumns();
        updateDateRangePickerDaysText();
        loadCaregiversData();
        getAgencyDocumentTypes();
    };

    let initialized = false;

    const fieldTypesMap = complianceConsts.fieldTypesMap;
    const ngTableOptions = {
        count: 25,
        sorting: { timeStamp: "desc" }
    };

    const localStorageKeyPrefix = "caregiversComplianceTableSettings2:";

    const defaultColumns = {
        "caregiver": {
            "Caregiver Name": true,
            "Caregiver ID": true,
            "Status": true,
            "Compliance Status": true,
            "Days to expire": true,
            "Languages": false,
            "Certifications": true,
            "Installation": false,
            "Last Seen": false,
            "Address": false,
            "Offices": false,
            "Phone": false,
            "Actions": true,
        },
        "items": {
            "Caregiver Name": true,
            "Caregiver ID": true,
            "Document Name": true,
            "Status": true,
            "Caregiver Status": true,
            "Certifications": true,
            "Days to expire": true,
            "Uploaded at": true,
            "Uploaded by": true,
            "Expiration date": true,
        },
    };

    const itemFields = {};

    let caregiversMap = DatabaseApi.caregivers() || {};

    let filterModalInstance;

    let documentTypes;

    $scope.complianceGlobalFilter = { val: "" };

    const emptyFilters = {
        certification: [],
        status: [],
        offices: [],
        expiryDate: { startDate: null, endDate: null },
        issue: [],
        showMissingItems: true,
        itemStatus: [],
        complianceStatus: [],
        showExpiredItems: false
    };

    $scope.filterComplianceBy = {
        certification: [],
        status: [{ id: 1 }],
        offices: [],
        expiryDate: { startDate: moment(), endDate: moment().add(29, "days") },
        issue: [],
        showMissingItems: true,
        itemStatus: [],
        complianceStatus: [],
        showExpiredItems: true
    };

    $scope.complianceCache = null;

    $scope.issues = [];

    $scope.tableColumns = {};

    $scope.tabs = [
        {
            key: "dashboard",
            title: "Dashboard",
            template: "admin/views/caregivers-compliance-dashboard.html",
        },
        {
            key: "items",
            title: "Items Status",
            template: "admin/views/caregivers-compliance-items.html",
        },
        {
            key: "caregiver",
            title: "Caregiver Status",
            template: "admin/views/caregivers-compliance-caregiver.html",
        },
    ];

    $scope.activeTab = "dashboard";

    $scope.activeFilters = [];

    let previousServerFiltersKeyVal = {};

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];

    const documentTypesMap = new Map();

    $scope.certifications = activeAgencyCertifications
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

    $scope.statuses = [
        { id: 1, label: "Active", value: "ACTIVE", text: "Active", statusClass: "green" },
        { id: 2, label: "On Hold", value: "ON_HOLD", text: "On Hold", statusClass: "yellow" },
        { id: 3, label: "On Leave", value: "ON_LEAVE", text: "On Leave", statusClass: "orange" },
        { id: 4, label: "Pending Application", value: "PENDING", text: "Pending Application", statusClass: "lightblue" },
        { id: 5, label: "Inactive", value: "SUSPENDED", text: "Inactive", statusClass: "azur" },
        { id: 6, label: "Terminated", value: "TERMINATED", text: "Terminated", statusClass: "red" },
        { id: 7, label: "Quit", value: "QUIT", text: "Quit", statusClass: "azur" }
    ];

    $scope.itemStatuses = [
        { id: 1, label: "Compliant", statusClass: "green" },
        { id: 2, label: "Missing", statusClass: "orange" },
        { id: 3, label: "Not Compliant", statusClass: "red" },
        { id: 4, label: "Pending Uploads", statusClass: "blue" },
        { id: 5, label: "Resolved", statusClass: "gray" },
        { id: 6, label: "Not due yet", statusClass: "gray" },
    ];

    $scope.complianceStatuses = [
        { id: 1, label: "Compliant", statusClass: "green" },
        { id: 2, label: "Not Compliant", statusClass: "red" }
    ];

    $scope.officesComponentOptions = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true
    };

    $scope.items = [];

    $scope.isDataLoaded = false;

    const now = moment();

    const compliantStatuses = complianceService.compliantStatuses;

    let documentNameDescendantsMap = new Map();

    let relevantIssues = new Set();

    $scope.dateRangeOptions = {
        ranges: {
            "Next 7 Days": [now, moment().add(6, "days").endOf("day")],
            "Next 14 Days": [now, moment().add(13, "days").endOf("day")],
            "Next 30 Days": [now, moment().add(29, "days").endOf("day")],
            "Next Year": [
                moment().add(1, "year").startOf("year"),
                moment().add(1, "year").endOf("year"),
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Next Month": [
                moment().add(1, "month").startOf("month"),
                moment().add(1, "month").endOf("month"),
            ],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
        },
        alwaysShowCalendars: true,
        applyClass: "btn-primary",
        locale: {
            direction: "ltr date-range-picker-v2",
            format: "D MMM YY",
        },
        autoApply: true,
        minDate: new Date("2001-01-01"),
        eventHandlers: {
            'apply.daterangepicker': () => {
                updateDateRangePickerDaysText();
            }
        }
    };

    $scope.offices = $scope.$resolve.offices
        .filter(office => office.active)
        .map(office => ({ id: office.id, label: office.name }));

    $scope.baseCols = [
        { title: "Effective Date" },
        { title: "Expiration Date" },
        { title: "View Upload" },
        { title: "Compliant" },
    ];

    $scope.baseColsAppending = [
        { title: "Uploaded At" },
        { title: "Uploaded By" },
        { title: "Actions" },
    ];

    function initTableColumns() {
        initTableColumnsUnit("caregiver");
        initTableColumnsUnit("items");
    }

    function initTableColumnsUnit(tab) {
        $scope.tableColumns[tab] = getColumns(tab);

        $scope.$watch(
            "tableColumns",
            () => onTableColumnsChange(tab),
            true
        );
    }

    function getColumns(tab) {
        const stored = getTableColumnsFromLocalStorage(tab);

        if (!stored) {
            return defaultColumns[tab];
        }

        const newColumns = JSON.parse(JSON.stringify(defaultColumns[tab]));

        for (const key in newColumns) {
            if (stored.hasOwnProperty(key)) {
                newColumns[key] = stored[key];
            }
        }

        return newColumns;
    }

    function getTableColumnsFromLocalStorage(tab) {
        const columns = Storage.getObject(localStorageKeyPrefix + tab);

        if (columns && Object.keys(columns).length) {
            return columns;
        }

        return null;
    }

    function onTableColumnsChange(tab) {
        if ($scope.tableColumns[tab]) {
            Storage.setObject(
                localStorageKeyPrefix + tab,
                $scope.tableColumns[tab]
            );
        }
    }

    $scope.setTab = (tabName) => {
        if (["items", "caregiver"].includes(tabName)) {
            !initialized && init();
            removeStatusFilter();
        }
        
        $scope.activeTab = tabName;
    }

    $scope.removeFilter = (filter) => {
        $scope.filterComplianceBy[filter.key] = JSON.parse(JSON.stringify(emptyFilters[filter.key]));
    }

    $scope.clickOpendRow = (close) => {
        if (close) {
            angular.element(document.getElementById("active-row")).remove();
            $scope.activeRow = null;
        } else {
            $rootScope.openCaregiverModal($scope.activeRow.id);
        }
    };

    $scope.$watch('filterComplianceBy', function () {
        $scope.clickOpendRow(true);
        setActiveFilters();
        filterTables();
    }, true);

    $scope.applyComplianceGlobalSearch = function (term) {
        const filter = { $: term };

        if ($scope.caregiverIncomplianceTable) {
            angular.extend($scope.caregiverIncomplianceTable.filter(), filter);
        }

        if ($scope.itemsTable) {
            angular.extend($scope.itemsTable.filter(), filter);
        }
    };

    function initComplianceTable() {
        $scope.caregiverIncomplianceTable = new NgTableParams(ngTableOptions, {
            counts: [],
            dataset: $scope.caregiverData,
            getData: (params) => {
                if (!$scope.caregiverData) {
                    return [];
                }

                const sorting = params.sorting();

                if (Object.keys(sorting).length > 0) {
                    const [column, order] = Object.entries(sorting)[0];

                    $scope.caregiverData.sort((a, b) => {
                        const compare = complianceService.compare(a, b, column);

                        return order === 'desc' ? compare * -1 : compare;
                    });
                }

                let items = $scope.caregiverData;

                if (params.filter()['$']) {
                    const q = params.filter()["$"].toLowerCase();
                    const num = parseInt(q, 10);

                    items = $scope.caregiverData.filter(item => {
                        return item.displayNameSearch.includes(q) ||
                            item.caregiver.displayId === num;
                    });
                }

                params.total(items.length);

                return items.slice((params.page() - 1) * params.count(), params.page() * params.count());
            },
        });
    }

    function applyComplianceItemsSearchFilter(items, query) {
        query = query.toLowerCase().trim();

        const numericQuery = Number(query);

        const regExp = new RegExp(query, "i");

        return items.filter(item => {
            if (regExp.test(item.caregiver.displayName)) {
                return true;
            }

            if (item.caregiver.displayId === numericQuery) {
                return true;
            }

            if (item.uploadedBy && regExp.test(item.uploadedBy)) {
                return true;
            }

            return false;
        });
    }

    function sortNumeric(a, b) {
        if (b < a) {
            return -1;
        }
        if (b > a) {
            return 1;
        }
        return 0;
    }

    function initItemsTable() {
        const options = {
            count: 25
        };

        $scope.itemsTable = new NgTableParams(options, {
            counts: [],
            dataset: $scope.itemsData,
            getData: function (params) {
                if (!$scope.itemsData) {
                    return [];
                }

                const sorting = params.sorting();

                if (Object.keys(sorting).length > 0) {
                    const [column, order] = Object.entries(sorting)[0];
                    $scope.itemsData.sort((a, b) => {
                        const compare = complianceService.compare(a, b, column);

                        return order === "desc" ? compare * -1 : compare;
                    });
                }

                const items = params.filter()['$']
                    ? applyComplianceItemsSearchFilter($scope.itemsData, params.filter()['$'])
                    : $scope.itemsData;

                params.total(items.length);

                return items.slice((params.page() - 1) * params.count(), params.page() * params.count());
            }
        });
    }

    const complianceFilterByMethods = {
        hasCertification: (compliance, selectedCertifications) => {
            if (!compliance.caregiver.certifications) {
                return false;
            }

            return compliance.caregiver.certifications.some(c => selectedCertifications.includes(c));
        },
        hasStatus: (compliance, selectedStatuses) => {
            return selectedStatuses.includes(compliance.caregiver.status);
        },
        hasItemStatus: (compliance, selectedItemStatuses) => {
            return selectedItemStatuses.includes(compliance.itemStatus);
        },
        hasComplianceStatus: (compliance, selectedComplianceStatuses) => {
            if (selectedComplianceStatuses.length >= 2 || selectedComplianceStatuses.length === 0) {
                return true;
            }

            const [selectedStatus] = selectedComplianceStatuses;

            return (compliance.caregiver.isCompliant ?? false) === (selectedStatus === "Compliant");
        },
        HasOffices: (compliance, offices) => {
            return compliance.caregiver.officeIds.some((officeId) => offices.includes(officeId));
        },
        hasExpiryDate: (compliance, startDate, endDate) => {
            const items = compliance.Incompliances ? compliance.Incompliances : [compliance];

            return items.some((item) => {
                const status = item.itemStatus ?? item.status;

                if ($scope.filterComplianceBy.showExpiredItems &&
                    !complianceService.compliantStatuses.includes(status)
                ) {
                    return true;
                }

                if (!item.expiryDate) {
                    return false;
                }

                return moment(item.expiryDate).isBetween(startDate, endDate);                
            });
        },
        hasIssue: (compliance) => {
            if (compliance.Incompliances) {
                return caregiverIncompliancesContainsDocumentTypeName(
                    compliance.Incompliances,
                    relevantIssues
                );
            }
            return relevantIssues.has(compliance.caregiverDocumentType.name);
        }
    };

    function caregiverIncompliancesContainsDocumentTypeName(incompliances, documentTypeNamesSet) {
        return incompliances.some(
            (incompliance) => documentTypeNamesSet.has(incompliance.caregiverDocumentType.name) ||
                (
                    incompliance.children &&
                    incompliance.children.length > 0 &&
                    caregiverIncompliancesContainsDocumentTypeName(incompliance.children, documentTypeNamesSet)
                )
        );
    }

    function filterIssues(items, statusColumn) {
        return items.flatMap(
            (item) => {
                const items = [];

                if (item.children && item.children.length > 0) {
                    items.push(filterIssues(item.children, statusColumn));
                }

                if (!complianceService.compliantStatuses.includes(item[statusColumn])) {
                    items.push(item);
                }

                return items;
            }
        );
    }

    function filterTables() {
        setRelevantIssues()

        $scope.caregiverData = filterTable($scope.complianceCache);
        $scope.itemsData = filterTable($scope.items);

        $scope.activeRow = $scope.activeRow
            ? $scope.caregiverData.find(row => row.caregiver === $scope.activeRow.caregiver)
            : null;

        initComplianceTable();
        initItemsTable();

        if ($scope.activeRow) {
            initActiveIssueTable($scope.activeRow);
        }

        if ($scope.complianceGlobalFilter.val) {
            $scope.applyComplianceGlobalSearch($scope.complianceGlobalFilter.val);
        }
    }

    const filters = [
        // Certifications
        {
            key: "certification",
            method: complianceFilterByMethods.hasCertification,
            values: () => $scope.filterComplianceBy.certification.map((obj) => $scope.certifications.find((cert) => cert.id === obj.id).label),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Certifications",
        },

        // Statuses
        {
            key: "status",
            method: complianceFilterByMethods.hasStatus,
            values: () => $scope.filterComplianceBy.status.map((obj) => $scope.statuses.find((stat) => stat.id === obj.id).value),
            operator: "isNotEmpty",
            chipLabel: (values) => values.map(status => $scope.getStatusByValue(status).text).join(", "),
            title: "Statuses",
        },

        // Item Statuses
        {
            key: "itemStatus",
            method: complianceFilterByMethods.hasItemStatus,
            values: () => $scope.filterComplianceBy.itemStatus.map((obj) => $scope.itemStatuses.find((stat) => stat.id === obj.id).label),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Item Statuses",
        },

        // Compliance Statuses
        {
            key: "complianceStatus",
            method: complianceFilterByMethods.hasComplianceStatus,
            values: () => $scope.filterComplianceBy.complianceStatus.map((obj) => $scope.complianceStatuses.find((stat) => stat.id === obj.id).label),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Compliance Statuses",
        },

        // Offices
        {
            key: "offices",
            method: complianceFilterByMethods.HasOffices,
            values: () => $scope.filterComplianceBy.offices.map((office) => office.id),
            operator: "isNotEmpty",
            chipLabel: (values) => $scope.showCaregiverOffices(values),
            title: "Offices",
        },

        // Time Period
        {
            key: "expiryDate",
            method: (compliance, selectedExpiryDate) => complianceFilterByMethods.hasExpiryDate(compliance, selectedExpiryDate.startDate, selectedExpiryDate.endDate),
            values: () => !$scope.isDataLoaded && previousServerFiltersKeyVal.expiryDate ? previousServerFiltersKeyVal.expiryDate : $scope.filterComplianceBy.expiryDate,
            operator: "hasStartAndEndDate",
            chipLabel: (values) => "From " + $filter("mfShortDate")(new Date(values.startDate)) + " To " + $filter("mfShortDate")(new Date(values.endDate)),
            title: "Time Period",
        },

        // Issues
        {
            key: "issue",
            method: complianceFilterByMethods.hasIssue,
            values: () => getSelectedIssues(),
            operator: "isNotEmpty",
            chipLabel: (values) => values.join(", "),
            title: "Issues",
        },

        // Missing Items
        {
            key: "showMissingItems",
            method: (compliance, _) => compliance.hasNotMissing === true,
            values: () => $scope.filterComplianceBy.showMissingItems,
            operator: "isFalse",
            chipLabel: (_) => "No missing items",
            title: "",
        },

        // Expired Items
        {
            key: "showExpiredItems",
            method: (compliance, _) => true,
            values: () => $scope.filterComplianceBy.showExpiredItems,
            operator: "isTrue",
            chipLabel: (_) => "Include items with issues",
            title: "",
        },
    ];

    function isActiveFilter(filter) {
        switch (filter.operator) {
            case "isNotEmpty":
                return filter.values().length > 0;

            case "hasStartAndEndDate":
                return filter.values().startDate && filter.values().endDate;

            case "isFalse":
                return filter.values() === false;

            case "isTrue":
                return filter.values() === true;

            default:
                console.error(`Missing case for operator "${filter.operator}" in isActiveFilter() method`);
        }
    }

    function filterTable(data) {
        if (data === null) return;

        return filterClientSide(data);
    }

    function setActiveFilters() {
        $scope.activeFilters = filters.filter(isActiveFilter);
    }

    function removeStatusFilter() {
        const key = $scope.activeTab === "caregiver" ? "complianceStatus" : "itemStatus";

        const filter = $scope.activeFilters.find(filter => filter.key === key);

        if (filter) {
            $scope.removeFilter(filter);
            setActiveFilters();
        }
    }

    function filterClientSide(data) {
        if ($scope.activeFilters.length === 0) {
            return data;
        }

        let filteredCompliance = data;

        filteredCompliance = filteredCompliance.filter((compliance) => {
            let isFiltered = true;

            for (const activeFilter of $scope.activeFilters) {
                if (!isFiltered) break;

                isFiltered = isFiltered && activeFilter.method(compliance, activeFilter.values());
            }

            return isFiltered;
        });

        return filteredCompliance;
    }

    $scope.openFilterModal = () => {
        filterModalInstance = $uibModal.open({
            templateUrl: 'admin/views/caregivers-compliance-filter-modal.html',
            size: 'md',
            scope: $scope,
            resolve: {},
            backdrop: true,
            backdropClass: 'transparent-backdrop',
            windowClass: "modal modal-slide-in-right uib-side-modal"
        });

        return filterModalInstance;
    }

    $scope.closeModal = () => {
        filterModalInstance && filterModalInstance.close();
    };

    $rootScope.$on("got_compliance_data", (_, data) => {
        $scope.isDataLoaded = true;
        $scope.caregiverData = [];
        $scope.itemsData = [];
        $scope.issues = [];
        $scope.items = [];
        $scope.activeRowIncompliances = [];

        const documentTypeParentMap = getDocumentTypeParentMap(data.documentTypes);

        for (const documentType of data.documentTypes) {
            documentType.parentCaregiverDocumentTypeId = documentTypeParentMap.get(documentType.id);
            documentTypesMap.set(documentType.id, documentType);
        }

        const mapped = data.records.map((record) => mapRecord(record, documentTypeParentMap));

        transformResponse(mapped, data.caregiverMissingItemsMap);

        setDocuments(data.documentTypes);

        $scope.complianceCache = mapped;

        setActiveFilters();
        
        filterTables();
    });

    $scope.transform = {
        caregiverStatusText: (status) => {
            const caregiverStatus = $scope.getStatusByValue(status);
            return caregiverStatus.text;
        },
        caregiverStatusColor: (status) => {
            const caregiverStatus = $scope.getStatusByValue(status);
            return caregiverStatus.statusClass;
        },
        caregiverOffices: (officeIds) => {
            $scope.showCaregiverOffices(officeIds);
        },
    };

    $scope.caregiversIncompliancesGet = (activeRow) => {
        if (activeRow) {
            getComplianceItemRecordsInfo(activeRow, true);
        }
    };

    function mapRecord(record, documentTypeParentMap) {
        return {
            caregiver: record.caregiver,
            displayNameSearch: record.caregiver.displayName?.toLowerCase(),
            Incompliances: record.items.map((item) => ({
                caregiver: record.caregiver,
                caregiverDocumentType: documentTypesMap.get(item.documentType.id),
                caregiverDocumentTypeId: item.documentType.id,
                parentCaregiverDocumentTypeId: documentTypeParentMap.get(item.documentType.id),
                status: item.status,
                effectiveDate: item.effectiveDate,
                expiryDate: item.expiryDate,
                dueDate: item.dueDate,
                isFollowupDocument: Boolean(item.documentType.followupParentId),
                followupParentId: item.documentType.followupParentId ?? null,
                groupType: item.documentType.groupType,
                requireReVerification: item.documentType.requireReVerification,
                name: item.documentType.name,
                uploadedAt: item.uploadedAt,
                uploadedBy: item.uploadedBy,
                fields: item.fields,
            })),
        };
    }

    function transformIssues(issues) {
        return issues.map((issue, index) => {
            return { id: index + 1, label: issue };
        });
    }

    $scope.isClickableComplianceItem = (incompliance) => {
        return incompliance.caregiverDocumentType.groupType === null && incompliance.complianceStatus !== "Missing";
    }
    
    function getDocumentTypeParentMap(documentTypes) {
        const documentTypeParentMap = new Map();

        for (const documentType of documentTypes) {
            for (const childId of documentType.children) {
                documentTypeParentMap.set(childId, documentType.id);
            }
        }

        return documentTypeParentMap;
    }

    function transformResponse(data, caregiverMissingItemsMap) {
        data.forEach((row) => {
            const caregiver = row.caregiver;

            let issuesAmount = 0;
            let minDaysToExpire = NaN;
            let minDaysIsForDueDate = false;

            for (const missingItem of caregiverMissingItemsMap.get(caregiver.id).required) {
                row.Incompliances.push({
                    caregiver: caregiver,
                    caregiverDocumentType: documentTypesMap.get(missingItem.id),
                    followupParentId: missingItem.followupParentId,
                    status: "Missing",
                });
            }

            const rowGroupedComplianceItems = complianceService.buildGroupItemsTree(row.Incompliances);
            const hasNonGroupDescendantSet = complianceService.getHasNonGroupDescendantSet(rowGroupedComplianceItems);
            const flattenedItems = complianceService.flattenGroupDocuments(rowGroupedComplianceItems);
            const flattendItemsMap = new Map(flattenedItems.map(obj => [obj.caregiverDocumentTypeId, obj]));
            const caregiverStatus = $scope.getStatusByValue(caregiver.status);

            row.caregiver = caregiver;
            row.Incompliances.forEach((incompliance) => {
                if (!compliantStatuses.includes(incompliance.status)) {
                    issuesAmount++;
                }

                if (incompliance.status === "Missing" || incompliance.status === "Not required") {
                    $scope.items.push({
                        caregiver: caregiver,
                        itemStatus: incompliance.status,
                        caregiverDocumentType: documentTypesMap.get(incompliance.caregiverDocumentType.id),
                        uniqueId: `${caregiver.id}-${incompliance.caregiverDocumentType.id}`,
                    });
                    return;
                }

                const expiryParams = complianceService.getExpiryOrDueDateParams(
                    incompliance.status,
                    incompliance.dueDate,
                    incompliance.expiryDate,
                    incompliance.requireReVerification
                );

                Object.assign(incompliance, expiryParams.data);

                incompliance.uploadedBy = $rootScope.getCAgencyMemberName(incompliance.uploadedBy);

                if (belongsToItemsView(incompliance, hasNonGroupDescendantSet)) {
                    // WE WANT TO SET A GROUP TREE FOR GROUP TYPE ITEMS, AND FLATTEN TO GET CHILDREN PARENTS
                    // FOR GROUP ITEM MODAL
                    if (incompliance.groupType) {
                        const itemGroupTree = flattendItemsMap.get(incompliance.caregiverDocumentTypeId);
                        if (itemGroupTree) {
                            incompliance.children = itemGroupTree.children;
                        }
                    }

                    incompliance.parentTrail = incompliance.parentCaregiverDocumentTypeId
                            ? $scope.getParentsTrail(flattendItemsMap, incompliance.parentCaregiverDocumentTypeId)
                            : null;

                    $scope.items.push({
                        caregiver: caregiver,
                        itemStatus: incompliance.status,
                        displayNameSearch: row.displayNameSearch || "",
                        caregiverId: caregiver.id,
                        caregiverDocumentType: documentTypesMap.get(incompliance.caregiverDocumentTypeId),
                        caregiverDocumentTypeId: incompliance.caregiverDocumentTypeId,
                        uniqueId: `${caregiver.id}-${incompliance.caregiverDocumentTypeId}`,
                        name: incompliance.name,
                        caregiverNextStatus: row.caregiverNextStatus,
                        caregiverNextStatusText: row.caregiverNextStatus ? $scope.getStatusByValue(row.caregiverNextStatus.status).text : null,
                        statusText: caregiverStatus.text,
                        statusColor: caregiverStatus.statusClass,
                        parentTrail: incompliance.parentTrail,
                        daysToExpireSort: incompliance.daysToExpireSort,
                        showDaysToExpire: incompliance.showDaysToExpire,
                        daysToExpireTextColor: incompliance.daysToExpireTextColor,
                        daysToExpireText: incompliance.daysToExpireText,
                        expiryDate: incompliance.expiryDate,
                        missingHireDate: incompliance.missingHireDate,
                        uploadedAt: incompliance.uploadedAt,
                        uploadedBy: incompliance.uploadedBy,
                        hasFileAttached: incompliance.hasFileAttached,
                        effectiveDate: incompliance.effectiveDate,
                        fields: incompliance.fields,
                    });
                }

                if (!isNaN(incompliance.daysToExpire) && !expiryParams.data.missingHireDate && !expiryParams.discardExpiry && incompliance.showDaysToExpire) {
                    if (incompliance.daysToExpire < minDaysToExpire || isNaN(minDaysToExpire)) {
                        minDaysIsForDueDate = expiryParams.isUsingDueDate;
                    }
                    
                    minDaysToExpire = isNaN(minDaysToExpire)
                        ? incompliance.daysToExpire
                        : Math.min(incompliance.daysToExpire, minDaysToExpire);
                }

                return incompliance;
            });


            row.caregiverNextStatusText = row.caregiverNextStatus ? $scope.getStatusByValue(row.caregiverNextStatus.status).text : null;
            row.statusText = caregiverStatus.text;
            row.statusColor = caregiverStatus.statusClass;
            row.Incompliances = rowGroupedComplianceItems;
            row.issuesAmount = issuesAmount;
            row.complianceStatus = issuesAmount === 0 ? "Compliant" : "Not Compliant";
            row.confirmed = calcConfirmed(row.appInstalled, row.appInstalledDate);
            row.strOffices = $scope.showCaregiverOffices(row.officeIds);
            row.minDaysToExpire = minDaysToExpire;
            row.minDaysToExpireText = complianceService.calculateDaysElapsed(
                minDaysToExpire,
                minDaysIsForDueDate ? "Due" : "Expires",
                minDaysIsForDueDate ? "Was due" : "Expired",
            );
            row.isValidMinExpiry = !isNaN(minDaysToExpire) && minDaysToExpire >= 0;
            row.shouldDisplayDaysToExpire = shouldDisplayDaysToExpire(row);
            row.minDaysToExpireSort = isNaN(row.minDaysToExpire) || !row.shouldDisplayDaysToExpire ? 'Ω' : row.minDaysToExpire; // Sorting with null values is inconsistent
        });
    }

    function belongsToItemsView(item, hasNonGroupDescendantSet) {
                // Show resolved only if it has records
        return (item.status !== "Resolved" || item.latestInstanceId) &&

                // Show missing only if it's a root level item
               (item.status !== "Missing" || !item.parentCaregiverDocumentTypeId) &&

               // Show group items only if they are root level and they don't have descendants that aren't group items 
               (!item.groupType || (!item.parentCaregiverDocumentTypeId && !hasNonGroupDescendantSet.has(item.caregiverDocumentTypeId)));
    }

    function calcConfirmed(isAppInstalled, appInstalledDate) {
        if (isAppInstalled) {
            return "installed";
        }
        
        if (appInstalledDate) {
            return "uninstalled";
        }

        return "notinstalled";
    }

    $scope.exportComplianceTable = (withFields) => {
        const tableData = $filter('filter')(
            $scope.activeTab === "items" ? $scope.itemsData : $scope.caregiverData,
            $scope.complianceGlobalFilter.val
        );

        const fieldsColumns = withFields ? getExportFieldsColumns(tableData) : [];

        const columnTitles = getColumnsToExport(fieldsColumns);

        const rows = [columnTitles.map(transformColumnNameForExport)];

        pushTableDataToExportRows(tableData, rows, columnTitles, fieldsColumns);

        if (rows.length === 0) {
            return;
        }

        const blob = createBlob(rows);

        serveCsvFile(blob);
    };

    function transformColumnNameForExport(columnName) {
        if (columnName === "Days to expire") {
            return "Days to expire/due";
        }

        if (columnName === "Expiration date") {
            return "Expiry/Due date";
        }

        return columnName;
    }

    function getColumnsToExport(fieldsColumns) {
        const exludedTitles = ["Photo", "Actions", "View", "Add"];

        const titles = [];

        for (const key in $scope.tableColumns[$scope.activeTab]) {
            if (exludedTitles.includes(key)) {
                continue;
            }

            if ($scope.tableColumns[$scope.activeTab][key]) {
                titles.push(key);
            }
        }

        if ($scope.activeTab === "caregiver") {
            titles.push("Total Issues");
        } else {
            titles.push("Effective Date");
            for (const fieldColumn of fieldsColumns) {
                titles.push(fieldColumn.columnName);
            }
        }

        return titles;
    }

    function getExportFieldsColumns(tableData) {
        const filteredDocumentTypes = new Set(
            tableData.map(r => r.caregiverDocumentTypeId)
        );

        const returnFields = [];

        if ($scope.activeTab === "items") {
            for (let [documentTypeId, fields] of Object.entries(itemFields)) {
                documentTypeId = Number(documentTypeId);

                if (filteredDocumentTypes.has(documentTypeId)) {
                    const doc = documentTypes.find(d => d.id === documentTypeId);

                    for (const field of fields) {
                        returnFields.push({
                            id: field.id,
                            columnName: `${doc.name} - ${field.name}`,
                            documentTypeId: documentTypeId,
                        });
                    }
                }
            }
        }

        return returnFields;
    }

    function pushTableDataToExportRows(tableData, rows, columnTitles, fieldsColumns) {
        for (const caregiver of tableData) {
            const exportMap = buildExportCellsMap(caregiver, fieldsColumns);

            const rowCells = columnTitles.map(columnTitle => buildExportCellContent(exportMap, columnTitle));

            rows.push(rowCells);
        }
    }

    function buildExportCellsMap(row, fieldsColumns) {
        const map = {
            "caregiver": () => {
                return {
                    "Caregiver Name": row.caregiver.displayName,
                    "Caregiver ID": row.caregiver.displayId || "",
                    "Languages": row.caregiver.languages.join(', '),
                    "Certifications": row.caregiver.certifications.join(', '),
                    "Installation": row.caregiver.confirmed === "installed" ? "Yes" : (row.caregiver.confirmed === "notinstalled" ? "No" : "Uninstalled"),
                    "Last Seen": row.caregiver.lastSeen ? moment(row.caregiver.lastSeen) : "",
                    "Address": row.caregiver.address,
                    "Offices": row.caregiver.strOffices,
                    "Phone": row.caregiver.phoneNumber,
                    "Status": $scope.getStatusByValue(row.caregiver.status).text,
                    "Compliance Status": row.complianceStatus,
                    "Days to expire": isNaN(row.minDaysToExpire) ? "" : row.minDaysToExpire,
                    "Total Issues": filterIssues(row.Incompliances, "status").length,
                }
            },
            "items": () => {
                const cols = {
                    "Caregiver Name": row.caregiver.displayName,
                    "Caregiver ID": row.caregiver.displayId || "",
                    "Caregiver Status": row.caregiver.status,
                    "Certifications": row.caregiver.certifications.join(", "),
                    "Document Name": row.name ?? row.caregiverDocumentType.name,
                    "Status": row.itemStatus,
                    "Uploaded at": row.uploadedAt ? $filter("mfShortDate")(new Date(row.uploadedAt)) : "",
                    "Uploaded by": row.uploadedBy ?? "",
                    "Expiration date": row.expiryDate ? $filter("mfShortDate")(new Date(row.expiryDate)) : "",
                    "Days to expire": isNaN(row.daysToExpireSort) ? "" : row.daysToExpireSort,
                    "Effective Date": row.effectiveDate ? $filter("mfShortDate")(new Date(row.effectiveDate)) : "",
                };

                for (const field of fieldsColumns) {
                    cols[field.columnName] = getFieldColumnValue(row, field);
                }

                return cols;
            },
        };

        return map[$scope.activeTab]();
    }

    function getFieldColumnValue(incompliance, field) {
        if (field.documentTypeId === incompliance.caregiverDocumentType.id) {
            const instanceField = incompliance.fields?.find(f => f.id === field.id);

            if (instanceField && instanceField.value) {
                return typeof instanceField.value === "string"
                    ? instanceField.value
                    : instanceField.value.text;
            }
        }

        return "";
    }

    function buildExportCellContent(exportMap, columnTitle) {
        if (!exportMap.hasOwnProperty(columnTitle)) {
            console.error(`"${columnTitle}" is missing from the map returned at buildExportCellsMap()`);

            return "";
        }

        const data = exportMap[columnTitle];

        const shouldAddQuotes = isNaN(data) || columnTitle === "Phone";

        return shouldAddQuotes ? `"${data}"` : data;
    }

    function createBlob(rows) {
        let csvContent = "";

        rows.forEach((rowArray) => {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        return new Blob([csvContent], { type: "text/csv" });
    }

    function serveCsvFile(blob) {
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute("download", "medflyt-incompliant-caregivers-export.csv");

        link.click();

        window.URL.revokeObjectURL(url);
    }

    $scope.openImportComplianceModal = () => {
        const progress = { value: 0 };

        const modal = $uibModal.open({
            templateUrl: "admin/views/drag-file-modal.html",
            controller: "dragFileModal",
            resolve: {
                accept: () => ".xls,.csv,.xlsx",
                title: () => "Import Compliance items from Excel",
                onDragFile: () => file => uploadComplianceTable(file),
                progress: () => progress
            }
        });

        const onSuccessUpload = response => {
            const { totalImported, totalSkipped, tableType } = response.data.result;
            toaster.pop(
                "success",
                `${tableType} Imported`,
                `Imported ${totalImported} items, skipped ${totalSkipped} items.`,
                3000
            );
        };

        const onErrorUpload = response => {
            let message = "Something went wrong...";

            if (response.data.type !== undefined) {
                message = response.data.missing;
            }

            toaster.pop("error", `Import failed`, message, 3000);
        };

        const uploadComplianceTable = file => {
            const formData = new FormData();

            formData.append("file", file, file.name);

            $http({
                url: wildcard(
                    `${Consts.api}hr/agencies/:agencyId/compliance_items/import_table`,
                    $rootScope.agencyId
                ),
                method: "POST",
                data: formData,
                headers: { "Content-Type": undefined },
                uploadEventHandlers: {
                    progress: e => (progress.value = (e.loaded / e.total) * 100)
                }
            })
                .then(onSuccessUpload)
                .catch(onErrorUpload)
                .finally(() => modal.dismiss());
        };
    }

    function loadCaregiversData() {
        if (!initialized) {
            return false;
        }
    }

    $rootScope.$on("caregiver_compliance_item_created", () => {
        loadCaregiversData();
    });

    $rootScope.$on("got_caregivers_data", () => {
        if (Object.keys(caregiversMap).length > 0) return;
        $scope.gotData = true;
        caregiversMap = DatabaseApi.caregivers() || {};
        loadCaregiversData();
    });

    $rootScope.$on("caregiver_changed", function (event) {
        caregiversMap = DatabaseApi.caregivers() || {};
    });

    $rootScope.$on("new_caregiver", function (event) {
        caregiversMap = DatabaseApi.caregivers() || {};
    });

    $scope.openDummyModal = (type) => {
        $uibModal.open({
            templateUrl: "admin/views/explain-modal.html",
            size: "md",
            controller: "boostModalCtrl",
            resolve: {
                textAmountToBroadcast: function () {
                    return 0;
                },
                priceOverallCents: function () {
                    return 0;
                },
                type: function () {
                    return type;
                }
            }
        });
    };

    $scope.handleNotesModalOpen = (profileId, profileName) => {
        entityNotesModalService.handleNotesModalOpen({ profileId, profileName });
    }

    $scope.getStatusByValue = (statusValue) => {
        return $scope.statuses.find((stat) => stat.value === statusValue);
    }

    $scope.getItemStatusByLabel = (label) => {
        return $scope.itemStatuses.find((status) => status.label === label);
    }

    $scope.showCaregiverOffices = (officeIds) => {
        if (!officeIds) return "";
        let presentedOffices;
        const includeInactiveOffices = $scope.filterComplianceBy.offices.find(office => office.id === -1) !== undefined;
        if (includeInactiveOffices) {
            presentedOffices = officeIds
                .map(officeId => offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.name)
        } else {
            presentedOffices = officeIds
                .map(officeId => $scope.offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.label)
        }

        return presentedOffices.join(", ");
    }

    $scope.handleViewIssueFileClick = (caregiverId, itemId) => {
        const url = `agencies/:agencyId/caregivers/:caregiverId/compliance_instances/:complianceInstanceId/preview`
            .replace(':agencyId', $rootScope.agencyId)
            .replace(':caregiverId', caregiverId)
            .replace(':complianceInstanceId', itemId);

        DatabaseApi.get(url).then((res) => {
            $window.open(res.data.fileUrl);
        }, (_) => {
            toaster.pop('error', 'Something went wrong', 'could not get file');
        });
    }

    $scope.clickComplianceRow = (row) => {
        if ($scope.activeRow && $scope.activeRow.id === row.id) {
            $scope.activeRow = null;
            return;
        }

        if ($scope.activeRowIssue) {
            $scope.activeRowIssue = null;
        }

        initActiveIssueTable(row);
    };

    function initActiveIssueTable(row) {
        $scope.activeRow = row;

        $scope.activeRowIncompliances = row.Incompliances;

        $scope.activeRowIssuesTable = new NgTableParams({
            count: 25,
            sorting: { name: "asc" }
        }, {
            counts: [],
            dataset: $scope.activeRowIncompliances,
            getData: (params) => complianceService.getCaregiverComplianceTrackingActiveRowData(params, $scope.activeRowIncompliances),
        });
    }

    $scope.clickComplianceItemRow = (row, rowIssue) => {
        if ($scope.activeRow && $scope.activeRow.id === row.id && $scope.activeRowIssue && $scope.activeRowIssue.id === rowIssue.id) {
            $scope.activeRowIssue = null;
            return;
        }

        $scope.activeRowIssue = rowIssue;
    }

    $scope.clickOpendRow = (close) => {
        if (close) {
            angular.element(document.getElementById("active-row")).remove();
            $scope.activeRow = null;
        } else {
            $rootScope.openCaregiverModal($scope.activeRow.id);
        }
    };

    // Should be used in "Caregiver" tab only
    function shouldDisplayDaysToExpire(row) {
        if (isNaN(row.minDaysToExpire)) return false;

        // Non compliant (some missing documents) and the non-missing docuemnt
        // With lowest days to expire value is a future date.
        if (row.complianceStatus === "Not Compliant" && row.minDaysToExpire >= 0) return false;

        return true;
    }

    $scope.addNewComplianceItem = (item, type, caregiverDocumentUploadId) => {
        if (item.caregiverDocumentType.groupType) {
            openGroupItemModal(item);
            return;
        }

        const newScope = $scope.$new();
        const documentType = item.caregiverDocumentType;

        item.caregiverId = item.caregiver.id;
        item.caregiverDocumentTypeId = documentType.id;
        newScope.item = item;
        newScope.caregiverId = item.caregiver.id;
        newScope.itemId = documentType.id;
        newScope.type = type;
        newScope.fields = itemFields[documentType.id];
        newScope.documentName = documentType.name;

        if (caregiverDocumentUploadId) {
            newScope.caregiverDocumentUploadId = caregiverDocumentUploadId;
        }

        if (item.defaultExpirySettings) {
            newScope.defaultExpirySettings = item.defaultExpirySettings;
        }

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/modules/compliance/views/caregiver-compliance-instance-modal.html',
            controller: 'caregiverComplianceInstanceModalCtrl',
            size: 'lg',
            scope: newScope,
            windowClass: "compliance-instance-modal"
        });

        modalInstance.result.then((res) => {
            item.itemStatus = res.data.isCompliant ? "Compliant" : "Not Compliant";
            item.status = item.itemStatus;
            item.hasFileAttached = res.data.hasAttachedFile;

            if (type === 'NEW_RECORD' || type === 'PENDING') {
                getComplianceItemRecordsInfo(item, true);
            }

            updateActiveRowTable();
        });
    };

    function updateDateRangePickerDaysText() {
        const startDate = $scope.filterComplianceBy.expiryDate.startDate;
        const endDate = $scope.filterComplianceBy.expiryDate.endDate;
        if (!startDate || !endDate) return;
        const diff = Math.abs(startDate.diff(endDate, 'days')) + 1;
        $scope.expiryDateRangeText = `${diff} days`;
    };

    function updateActiveRowTable() {
        if ($scope.activeRow && $scoppe.activeTab === "items") {
            const updatedRow = $scope.caregiverData.find(r => r.id === $scope.activeRow.id);
            updatedRow && initActiveIssueTable(updatedRow);
        }
    }

    const getAgencyDocumentTypes = function () {
        if (!$rootScope.agencyId || !$rootScope.agencyMemberId) return;

        const url = `agencies/:agencyId/agency_members/:agencyMemberId/document_types`
            .replace(':agencyId', $rootScope.agencyId)
            .replace(':agencyMemberId', $rootScope.agencyMemberId);

        DatabaseApi.get(url).then((res) => {
            documentTypes = res.data.documentTypes;

            documentTypes.forEach(type => {
                if (type.complianceData.fields) {
                    itemFields[type.id] = type.complianceData.fields;
                }
            });
        });
    };

    const setComplianceItemRecordsInfo = (row, data) => {
        const caregiverDocumentTypeId = row.caregiverDocumentTypeId ?? row.caregiverDocumentType.id;

        row.instances = data.instances.filter(i => !i.fictiveType);
        row.caregiverPendingUploads = data.caregiverPendingUploads;
        row.historyStatusChanges = data.historyStatusChanges;
        row.fields = itemFields[caregiverDocumentTypeId];

        complianceService.setItemFieldsModels(row.fields);
        if (row.instances.length > 0) {
            row.cols = [
                ...$scope.baseCols,
                ...row.fields.map(field => ({ title: field.name })),
                ...$scope.baseColsAppending,
            ];
        }

        const requiredFields = Object.keys(row).filter(field => {
            if (fieldTypesMap[field] && row[field] === true) {
                return field;
            }
        });

        row.modalFields = requiredFields.map(field => {
            const fieldName = field.replace("requires", "");
            return fieldName[0].toLowerCase() + fieldName.substr(1);
        });

        row.instances.forEach(instance => {
            complianceService.setItemFieldsModels(instance.fields);
        });

        row.activeUploadTab = 0;
    };

    const getComplianceItemRecordsInfo = (row, loadAfterAction = false) => {
        if ($scope.activeComplianceItemStatusRow && $scope.activeComplianceItemStatusRow.isLoadingRecords) return;
        if (row.caregiverPendingUploads && !loadAfterAction) {
            $scope.activeComplianceItemStatusRow = row;
            return;
        }

        $scope.activeComplianceItemStatusRow = row;
        row.isLoadingRecords = true;
        complianceService.getComplianceItemRecordsInfo(
            row.caregiverId ?? row.caregiver.id,
            row.caregiverDocumentTypeId ?? row.caregiverDocumentType.id
        )
            .then((res) => {
                setComplianceItemRecordsInfo(row, res.data);
            }).catch((err) => {
                console.error(err);
                toaster.pop('error', "Failed to get compliance item records info");
            }).finally(() => {
                row.isLoadingRecords = false;
            });
    };

    const openGroupItemModal = (item) => {
        item.fields = itemFields[item.caregiverDocumentTypeId];
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/modules/compliance/views/caregiver-compliance-group-modal.html',
            controller: 'caregiverComplianceGroupModalCtrl',
            size: 'lg',
            windowClass: 'top-top',
            resolve: {
                parentItem: () => item,
                caregiverId: () => item.caregiverId
            }
        });

        modalInstance.result.then(async (res) => {
            loadCaregiversData();
        }, () => { });
    };

    $scope.clickComplianceItemStatusRow = (row) => {
        if (!$scope.isClickableComplianceItem(row)) return;
        if ($scope.activeComplianceItemStatusRow && $scope.activeComplianceItemStatusRow.uniqueId === row.uniqueId) {
            $scope.activeComplianceItemStatusRow = null;
            return;
        }
        getComplianceItemRecordsInfo(row);
    };

    $scope.getParentsTrail = (map, docId) => {
        const doc = map.get(docId);

        if (doc?.parentCaregiverDocumentTypeId) {
            return $scope.getParentsTrail(map, doc.parentCaregiverDocumentTypeId) + " » " + doc.name;
        }

        return doc?.name;
    };

    function setDocuments(documents) {
        const documentsMap = new Map();
        documentNameDescendantsMap = new Map();

        for (const document of documents) {
            documentsMap.set(document.caregiverDocumentTypeId, document);
        }

        for (const document of documents) {
            document.children = document.children.map(id => {
                const doc = documentsMap.get(id);

                if (doc) {
                    doc.isChild = true;
                }

                return doc;
            }).filter(doc => Boolean(doc));
        }

        for (const document of documents) {
            const decendantNames = getDocumentDescendants([document]).map(doc => doc.name);

            if (decendantNames) {
                documentNameDescendantsMap.set(document.name, decendantNames);
            }
        }

        $scope.issues = transformIssues(getIssues(documents.filter(d => !d.isChild)));
    }

    function getRelevantIssues(documentNames) {
        const issues = [];

        for (const documentName of documentNames) {
            const children = documentNameDescendantsMap.get(documentName);

            if (children) {
                issues.push(...children);
            }   
        }

        return new Set(issues);
    }

    function getDocumentDescendants(documents) {
        const descendants = [...documents];

        for (const document of documents) {
            if (document.children) {
                descendants.push(
                    ...getDocumentDescendants(document.children)
                );
            }
        }

        return descendants;
    }

    function getSelectedIssues() {
        return $scope.filterComplianceBy.issue.map((obj) => $scope.issues.find((issue) => issue.id === obj.id).label);
    }

    function getIssues(documents) {
        const issues = new Set();

        for (const document of documents) {
            issues.add(document.name);

            if (document.children) {
                getIssues(document.children).forEach(issue => issues.add(issue));
            }
        }

        return Array.from(issues);
    }

    function setRelevantIssues() {
        relevantIssues = getRelevantIssues(getSelectedIssues());

        $scope.filterComplianceBy.issue = [...relevantIssues].map((issueName) => $scope.issues.find((issue) => issue.label === issueName));
    }
};