import { LocalDateTime } from "js-joda";
import { Caregiver } from "../../../../scripts/messages/caregiver";
import { CaregiverId, VisitBroadcastId, VisitInstanceId } from "../../../../scripts/messages/ids";
import { VisitInstance } from "../../../../scripts/messages/visit";
import { VisitBroadcastService } from "../../visitBroadcast.service";
import { VisitBroadcastPartialAssingParams } from "../../visitBroadcast.types";
import "./caregivers-visits-requests-table.component.scss";
import {
  CaregiverInShift,
  CaregiverVisitRequest,
  CaregiverVisitsRequestsTableBindings,
  Shift,
  ShiftId
} from "./caregivers-visits-requests-table.types";
import {
  arePatternShiftsEqual,
  decouplePatternShiftIdToShiftDetails,
  generatePatternShiftId,
  getShiftId,
} from "./caregivers-visits-requests-table.utils";

const daysOfWeekDisplay = {
  SUNDAY: "Sunday",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday"
}

//! @ngInject
class caregiversVisitsRequestsTableCtrl
  implements ng.IComponentController, CaregiverVisitsRequestsTableBindings
{
  caregiverEngagements!: CaregiverVisitRequest[];
  visitBroadcastType!: "PATTERN" | "SINGLES";
  visitInstances!: VisitInstance[];
  visitBroadcastId!: VisitBroadcastId;
  closeModal!: () => void;

  shifts: Shift[];
  selectedCaregiversInShifts: Map<ShiftId, CaregiverId>;
  caregivers: CaregiverInShift[];

  constructor(
    private $filter: ng.IFilterService,
    private $rootScope: ng.IRootScopeService,
    private toaster: toaster.IToasterService,
    private mfModal: any,
    private visitBroadcastService: VisitBroadcastService
  ) {
    this.shifts = [];
    this.selectedCaregiversInShifts = new Map<ShiftId, CaregiverId>();
    this.caregivers = [];
  }

  $onInit() {
    this.mapVisitInstancesTimes();
  }

  $onChanges(changesObj) {
    if ("visitInstances" in changesObj) {
      this.mapVisitInstancesTimes();
    }

    if ("caregiverEngagements" in changesObj) {
      const caregiverEngagements = changesObj.caregiverEngagements.currentValue;

      if (caregiverEngagements.length > 0) {
        this.setShiftsOnEngagementDataChange();
        this.caregivers = this.mapCaregiversIntoCaregiversInShifts(this.caregiverEngagements);
      } else {
        // Without engagements there's no point in having shifts.
        this.shifts = [];
      }
    }
  }

// --- Arranging the data on init ----

  mapCaregiversToInstancesIds(caregiverEngagements: CaregiverVisitRequest[]) {
    const caregiversByInstanceIdMap = new Map<VisitInstanceId, Caregiver[]>();

    caregiverEngagements.forEach((engagement) => {
      engagement.requestedInstances.forEach((instanceId) => {
        const caregiversByShifts = caregiversByInstanceIdMap.get(instanceId);

        if (caregiversByShifts === undefined) {
          caregiversByInstanceIdMap.set(instanceId, [engagement.caregiver]);
        } else {
          caregiversByShifts.push(engagement.caregiver);
          caregiversByInstanceIdMap.set(instanceId, caregiversByShifts);
        }
      });
    });

    return caregiversByInstanceIdMap;
  }

  mapVisitInstancesTimes() {
    // This is a very ugly walkaround I have to do because for some reason the instances start and end time are strings at runtime,
    // even though they are listed in the interface as LocalDateTime.
    const updatedVisitInstances = this.visitInstances.map((instance) => {
      return {
        ...instance,
        startTime: LocalDateTime.parse(instance.startTime.toString()),
        endTime: LocalDateTime.parse(instance.endTime.toString()),
      };
    });

    this.visitInstances = updatedVisitInstances;
  }

  mapCaregiversIntoCaregiversInShifts(caregiverEngagements: CaregiverVisitRequest[]): CaregiverInShift[] {
    return caregiverEngagements
      .filter((engagement) => this.getRequestedShifts(engagement.requestedInstances).length !== 0)
      .map((e) => {
        const requestedShifts = this.getRequestedShifts(e.requestedInstances);
        return {
          ...e.caregiver,
          numOfRequestedShifts: requestedShifts.length,
          requestedShifts,
        };
      });
  }

  setShiftsOnEngagementDataChange() {
    const caregiversByInstanceIdMap = this.mapCaregiversToInstancesIds(this.caregiverEngagements);

    if (this.visitBroadcastType === "PATTERN") {
      this.shifts = this.getPatternVisitShifts(this.visitInstances, caregiversByInstanceIdMap);
    } else {
      this.shifts = this.getSinglesVisitShifts(this.visitInstances, caregiversByInstanceIdMap);
    }
  }

  getRequestedShifts(requestedInstances: VisitInstanceId[]) {
    return this.shifts.filter((shift) => {
      if (shift.type === "PATTERN") {
        return shift.visitInstancesIds.some((instanceId) =>
          requestedInstances.includes(instanceId)
        );
      }

      return requestedInstances.includes(shift.visitInstanceId);
    });
  }

  getPatternVisitShifts(
    visitInstances: VisitInstance[],
    caregiversByInstanceIdMap: Map<VisitInstanceId, Caregiver[]>
  ) {
    const shifts: Shift[] = [];
    const instancesByShiftKey = new Map<string, VisitInstanceId[]>();

    for (const currInstance of visitInstances) {
      const currShift = {
        startTime: currInstance.startTime.toLocalTime(),
        endTime: currInstance.endTime.toLocalTime(),
        dayOfWeek: currInstance.startTime.dayOfWeek(),
      };

      const shiftKey = generatePatternShiftId(currShift);
      const visitInstancesInShift = instancesByShiftKey.get(shiftKey);

      if (visitInstancesInShift === undefined) {
        instancesByShiftKey.set(shiftKey, [currInstance.id]);
      } else {
        visitInstancesInShift.push(currInstance.id);
        instancesByShiftKey.set(shiftKey, visitInstancesInShift);
      }
    }

    for (const [shiftId, visitInstancesIds] of instancesByShiftKey.entries()) {
      const patternShiftDetails = decouplePatternShiftIdToShiftDetails(shiftId);

      shifts.push({
        type: "PATTERN",
        ...patternShiftDetails,
        caregivers: visitInstancesIds.flatMap(
          (instanceId) => caregiversByInstanceIdMap.get(instanceId) ?? []
        ),
        visitInstancesIds,
        displayTitle: `${daysOfWeekDisplay[patternShiftDetails.dayOfWeek.toString()]}s`,
        secondaryDisplayTitle: `${patternShiftDetails.startTime} - ${patternShiftDetails.endTime}`,
        isOvernight: false,
      });
    }

    return shifts;
  }

  getSinglesVisitShifts(
    visitInstances: VisitInstance[],
    caregiversByInstanceIdMap: Map<VisitInstanceId, Caregiver[]>
  ) {
    const shifts: Shift[] = [];

    for (const currInstance of visitInstances) {
      shifts.push({
        type: "SINGLES",
        startTime: currInstance.startTime,
        endTime: currInstance.endTime,
        caregivers: caregiversByInstanceIdMap.get(currInstance.id) ?? [],
        visitInstanceId: currInstance.id,
        displayTitle: `${this.$filter("mfShortDate")(currInstance.startTime.toLocalDate())}`,
        secondaryDisplayTitle: `${this.$filter("mfShortTime")(
          currInstance.startTime
        )} - ${this.$filter("mfShortTime")(currInstance.endTime)}`,
        isOvernight: currInstance.startTime
          .toLocalDate()
          .isBefore(currInstance.endTime.toLocalDate()),
      });
    }

    return shifts;
  }

  // ---- User actions on component ----

  toggleShiftSelection(shift: Shift, caregiverId: CaregiverId) {
    const shiftId = getShiftId(shift);

    if (this.selectedCaregiversInShifts.has(shiftId)) {
      const shiftKey = getShiftId(shift);
      this.selectedCaregiversInShifts.delete(shiftKey);
    } else {
      this.selectedCaregiversInShifts.set(shiftId, caregiverId);
    }
  }

  getSelectButtonClass(shift: Shift, caregiverId: CaregiverId) {
    const shiftId = getShiftId(shift);
    return this.isShiftSelected(shift) &&
      this.selectedCaregiversInShifts.get(shiftId) === caregiverId
      ? "select-to-assign-btn-selected"
      : "btn-primary";
  }

  getSelectBtnText(caregiverId: CaregiverId, shifts: Shift[]) {
    return !this.isChooseAllDisabled(caregiverId, shifts) &&
      !this.shouldSelectAllShifts(caregiverId, shifts)
      ? "Unselect"
      : "Select";
  }

  canSelectShift(shift: Shift, caregiverId: CaregiverId) {
    const shiftId = getShiftId(shift);
    const chosenCaregiverForShift = this.selectedCaregiversInShifts.get(shiftId);
    const isShiftChosenForAnotherCaregiver =
      chosenCaregiverForShift !== undefined && chosenCaregiverForShift !== caregiverId;

    return !isShiftChosenForAnotherCaregiver;
  }

  caregiverRequestedShift(shift: Shift, caregiverId: CaregiverId) {
    return shift.caregivers.some((c) => c.id === caregiverId);
  }

  isShiftSelected(shift: Shift): boolean {
    const shiftId = getShiftId(shift);
    return this.selectedCaregiversInShifts.has(shiftId);
  }

  shouldSelectAllShifts(caregiverId: CaregiverId, caregiverShifts: Shift[]) {
    const canBeSelectedShifts = caregiverShifts.filter((shift) =>
      this.canSelectShift(shift, caregiverId)
    );
    const selectedShifts = caregiverShifts.filter((shift) => {
      const shiftId = getShiftId(shift);
      return this.selectedCaregiversInShifts.get(shiftId) === caregiverId;
    });

    return selectedShifts.length !== canBeSelectedShifts.length;
  }

  isChooseAllDisabled(caregiverId: CaregiverId, caregiverShifts: Shift[]) {
    const canBeSelectedShifts = caregiverShifts.filter((shift) =>
      this.canSelectShift(shift, caregiverId)
    );
    const selectedShifts = caregiverShifts.filter((shift) => {
      const shiftId = getShiftId(shift);
      return this.selectedCaregiversInShifts.get(shiftId) === caregiverId;
    });

    return canBeSelectedShifts.length === 0 && selectedShifts.length === 0;
  }

  toggleAllShiftsSelectionForCaregiver(caregiverId: CaregiverId, caregiverShifts: Shift[]) {
    const shouldSelectAll = this.shouldSelectAllShifts(caregiverId, caregiverShifts);
    const canBeSelectedShifts = caregiverShifts.filter((shift) =>
      this.canSelectShift(shift, caregiverId)
    );

    for (const shift of canBeSelectedShifts) {
      const shiftId = getShiftId(shift);

      if (shouldSelectAll) {
        this.selectedCaregiversInShifts.set(shiftId, caregiverId);
      } else {
        this.selectedCaregiversInShifts.delete(shiftId);
      }
    }
  }

  clearSelectedAssignments() {
    this.selectedCaregiversInShifts = new Map<ShiftId, CaregiverId>();
  }

  buildRejectVisitBodyParams(caregiverId: CaregiverId, caregiverRequestedShifts: Shift[]) {
    const visitInstancesIds: VisitInstanceId[] = [];

    for (const shift of caregiverRequestedShifts) {
      if (shift.type === "PATTERN") {
        visitInstancesIds.push(...shift.visitInstancesIds);
      } else {
        visitInstancesIds.push(shift.visitInstanceId);
      }
    }

    return {
      visitInstances: visitInstancesIds,
    };
  }

  buildAssignCaregiverRequestBodyParams() {
    const requestBody: VisitBroadcastPartialAssingParams = { assignParams: [] };

    for (const [shiftId, caregiverId] of this.selectedCaregiversInShifts) {
      if (this.visitBroadcastType === "PATTERN") {
        // Trying to find the shift with the id that matches to 'shiftId', to take it's requested instances ids and build
        // the assign params.
        const currentShiftTimes = decouplePatternShiftIdToShiftDetails(shiftId as string);

        const currentShift = this.shifts.find((shift) => {
          if (shift.type === "PATTERN") {
            return arePatternShiftsEqual({ ...shift }, currentShiftTimes);
          }
        });

        const visitInstancesIds =
          currentShift !== undefined && currentShift.type === "PATTERN"
            ? currentShift.visitInstancesIds
            : [];

        requestBody.assignParams.push({
          caregiverId,
          visitInstancesIds,
        });
      } else {
        const singleShiftId = shiftId as VisitInstanceId;

        requestBody.assignParams.push({
          caregiverId: caregiverId,
          visitInstancesIds: [singleShiftId],
        });
      }
    }

    return requestBody;
  }

  assignShiftsToCaregivers() {
    const requestBody = this.buildAssignCaregiverRequestBodyParams();

    this.visitBroadcastService.assignCaregiversToVisit(this.visitBroadcastId, requestBody).then(
      (res) => {
        if (res.data.assignWithIncreasedCaregiverOvertime) {
          this.toaster.pop({
            type: "warning",
            title: "Warning",
            body: `Successfully assigned caregiver with increased caregiver overtime`,
          });
        } else {
          this.toaster.pop("success", "Successfully assigned caregiver");
        }
        this.$rootScope.$emit("refresh_visits");
        this.closeModal();
      },
      (err) => {
        let errorMessage = "Failed to assign caregiver";
        if (err.status === 403) {
          errorMessage = "Not permitted to increase caregiver overtime.";
        }
        this.toaster.pop("error", "Oops...", errorMessage);
      }
    );
  }

  openCaregiverModal(caregiverId: CaregiverId, caregiver: Caregiver) {
    this.$rootScope.openCaregiverModal(caregiverId, caregiver);
  }

  openShouldRejectModal(caregiverId: CaregiverId, caregiverRequestedShifts: Shift[]) {
    const modal = this.mfModal.create({
      subject: "Are You Sure?",
      variant: "warning",
      message:
        "Clicking 'Reject' will result in rejecting the entire request (all of the caregiver's requested shifts).",
      cancelLabel: "I changed my mind",
      confirmLabel: "Reject Request",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: () => {
        const requestBody = this.buildRejectVisitBodyParams(caregiverId, caregiverRequestedShifts);

        this.visitBroadcastService
          .rejectCaregiverVisitRequest(caregiverId, this.visitBroadcastId, requestBody)
          .then((_res) => {
            modal.close();
            this.toaster.pop("success", "Successfully rejected caregiver's request.");
            this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });
          })
          .catch((_err) => {
            this.toaster.pop("error", "Oops...", "Failed to reject the caregiver's request.");
          });
      },
    });
  }
}

export const caregiverVisitRequestsTable = {
  templateUrl:
    "admin/modules/visit-broadcast/components/caregivers-visits-requests-table/caregivers-visits-requests-table.component.html",
  controller: caregiversVisitsRequestsTableCtrl,
  controllerAs: "ctrl",
  bindings: {
    caregiverEngagements: "<",
    visitBroadcastType: "<",
    visitInstances: "<",
    visitBroadcastId: "<",
    closeModal: "&",
  },
};
