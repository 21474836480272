export function isDefined<T>(value: T | undefined): value is NonNullable<T> {
    return value !== undefined && value !== null;
}

export function assertDefined<T>(value: T | null | undefined, description: string): T {
    if (isDefined(value)) {
        return value;
    }

    throw new Error(`Expected defined value for "${description}", received ${value} instead.`);
}

export function fmap<S, T>(val: S | null, f: (x: S) => T): T | null {
    if (val === null) {
        return null;
    } else {
        return f(val);
    }
}

export function groupBy<T extends object, K extends keyof T>(collection: T[], iteratee: K) {
    const map: Map<T[K], T[]> = new Map();

    for (const item of collection) {
        const accumalated = map.get(item[iteratee]);
        if (accumalated === undefined) {
            map.set(item[iteratee], [item]);
        } else {
            map.set(item[iteratee], [...accumalated, item]);
        }
    }

    return map;
}

export function upperCaseFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function arrayAt<T>(arr: T[], idx: number): T | undefined {
    return arr[idx];
}
