import { AgencyMemberPermission } from "../messages/agency_role";
import { UserId } from "../messages/ids";
import { JWTPayload, LoginData, OldLoginResponse } from "../messages/user_auth";
import jwt_decode from "jwt-decode";
export interface OldResponseJWTWrapper extends OldLoginResponse {
  loginType: "JWT" | "AUTH_TOKEN";
  refreshToken?: string;
}
//! @ngInject
export class LoginDataStorageService {
  constructor(private $rootScope: ng.IRootScopeService, private Storage: Storage) {}

  setLoginDataFromAccessToken = (
    accessToken: string,
    refreshToken: string,
    permissions: AgencyMemberPermission[]
  ) => {
    const userDetails: JWTPayload = jwt_decode(accessToken);
    const loginDataWithPermissions: LoginData | null =
      userDetails.userLoginDetails === null
        ? null
        : {
            type: "AgencyMemberLoginData",
            agencyMember: userDetails.userLoginDetails.agencyMember,
            agency: userDetails.userLoginDetails.agency,
            coordinator: userDetails.userLoginDetails.coordinator,
            active: userDetails.userLoginDetails.active,
            agencyInactive: userDetails.userLoginDetails.agencyInactive,
            permissions: permissions,
          };
    const oldResponseWrapper: OldResponseJWTWrapper = {
      authToken: accessToken,
      refreshToken: refreshToken,
      superuser: userDetails.authData.superuser,
      userId: userDetails.authData.userId,
      loginData: loginDataWithPermissions,
      loginType: "JWT",
    };

    if (userDetails.authData.email !== null) {
      this.setLoginData(
        oldResponseWrapper,
        userDetails.authData.userId,
        userDetails.authData.email,
        accessToken
      );
    }

    return oldResponseWrapper;
  };
  setLoginData = (
    userDetails: OldResponseJWTWrapper,
    userId: UserId,
    email: string,
    token: string
  ) => {
    this.$rootScope.user = { ...userDetails };
    if (userDetails.loginData !== null) {
      (this.$rootScope.user.agency = userDetails.loginData.agency),
        (this.$rootScope.user.agencyMember = userDetails.loginData.agencyMember),
        (this.$rootScope.user.coordinator = userDetails.loginData.coordinator),
        (this.$rootScope.user.type = userDetails.loginData.type),
        (this.$rootScope.user.permissions = userDetails.loginData.permissions);
      this.$rootScope.agencyMemberId = userDetails.loginData.agencyMember.id;
      this.$rootScope.agencyId = userDetails.loginData.agency.id;
      if (userDetails.loginData.agency)
        this.$rootScope.setCompanyType(userDetails.loginData.agency.organizationType);
    }
    this.Storage.set("userEmail", email);
    this.$rootScope.token = token;
    this.$rootScope.userId = userId;
    if (this.$rootScope.user.coordinator)
      this.$rootScope.user.displayName =
        this.$rootScope.user.coordinator.firstName +
        " " +
        this.$rootScope.user.coordinator.lastName;

    this.$rootScope.user.email = email.toLocaleLowerCase();
    this.$rootScope.user.verified = true;
    this.$rootScope.refreshToken = userDetails.refreshToken;
    this.$rootScope.user.superuser = userDetails.superuser;
    this.$rootScope.user.admin = userDetails.superuser
      ? true
      : userDetails.loginData?.coordinator?.jobTitle === "Admin";
    this.Storage.setObject("user", this.$rootScope.user);
  };
}
